import React from 'react'
import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next'
import {Link} from 'react-router-dom'

export default function BreadcrumbComponent({ local}) {
    const {t} = useTranslation()
    return  <Breadcrumb>
    <Link to={'/'+local}> <Breadcrumb.Item>{t('home')}</Breadcrumb.Item></Link>
<Breadcrumb.Item>
         {t('praticalInfos')}
</Breadcrumb.Item>

 </Breadcrumb>
}