import React from 'react'
import { connect } from 'react-redux'
import PracticalInfoAr from './PracticalInfoAr'
import PracticalInfoEs from './PracticalInfoEs'
import PracticalInfoEn from './PracticalInfoEn'
import PracticalInfoFr from './PracticalInfoFr'
import './PracticalInfo.css'

function PracticalInfo({local}) {
  switch (local) {
      case 'fr':
          return (<PracticalInfoFr />)
         
          case 'en':
            return (<PracticalInfoEn />)
         
          case 'es':
            return (<PracticalInfoEs />)
         
          case 'ar':
            return (<PracticalInfoAr />)
           

  
      default:
        return (<PracticalInfoEn />)
         
  }
}




const mapStateToProps = state => {
    return {
      ...state.Local,
   
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
     //setFilters: (filters)=>dispatch(setFilters(filters))
  }
  }
  
  const appConnect = connect(mapStateToProps, mapDispatchToProps)(PracticalInfo)
  
  export default appConnect;