export const PERSISTANCE_KEY = "VT-048";
export const API_ENDPOINT =
  "https://api.visittanger.com";
//export const API_ENDPOINT = 'http://127.0.0.1:4040'
export const CDN_ENDPOINT = "https://d3lq8p6p6r1qxf.cloudfront.net";
export const USER_IP_ENDPOINT = "https://json.geoiplookup.io";
export const API_NEWSLETTER = API_ENDPOINT + '/newsletter'
export const API_CONTACT = API_ENDPOINT + '/contact'
export const API_PLACES = API_ENDPOINT + "/cache/places";
export const API_MICE = API_ENDPOINT + "/mice";
export const API_QUOTES = API_ENDPOINT + "/quote";
export const API_ARTICLES = API_ENDPOINT + "/articles";
export const API_DEALS = API_ENDPOINT + "/cache/places?isDeal=true";
export const API_TAGS = API_ENDPOINT + "/tags";
export const API_CITIES = API_ENDPOINT + "/city";
export const API_TOURS = API_ENDPOINT + "/tours";
export const API_STATS = API_ENDPOINT + "/cache/bi";
export const API_STATS_LIVE = API_ENDPOINT + "/bi";
export const API_AUTH = API_ENDPOINT + "/auth/login";
export const API_AUTH_IS_LOGGED_IN = API_ENDPOINT + "/auth/isLoggedIn"; //Not implemented yet
export const API_UPLOAD = API_ENDPOINT + "/media";
export const CLOUDINARY = 'https://res.cloudinary.com/comepic/image/fetch/c_fit,g_center,w_400/'
export const LOGO_CRT = "https://d3lq8p6p6r1qxf.cloudfront.net/1592758931946.png";
export const LOGO_OFFICE_TOURISME = "https://d3lq8p6p6r1qxf.cloudfront.net/1605197050827.png";
export const LOGO_REGION = "https://d3lq8p6p6r1qxf.cloudfront.net/1593206370257.jpeg";
export const LOGO_ONDA = "https://d3lq8p6p6r1qxf.cloudfront.net/1605197188641.png"

export const NO_IMAGE =
  "https://d3lq8p6p6r1qxf.cloudfront.net/1579059018898.png";

export const DEFAULT_BG =
  "https://d3lq8p6p6r1qxf.cloudfront.net/1579983146302.octet-stream";

export const DEFAULT_BG_TWO =
  "https://d3lq8p6p6r1qxf.cloudfront.net/1579920053008.jpeg";

export const DO_ARRAY = [
  "activite",
  "restaurant",
  "evenement",
  "nightlife",
  "shopping",
  "transport",
  'hotel',
  'agence-de-voyages',
];

export const DEALS_ARRAY = [
  'activite','hotel', 'packages', 'visite-guidee'
]

export const EXCLUDE_FROM_SEE_ARRAY = [
  'activite', "nightlife","shopping","transport", "restaurant",
  "evenement",
  'hotel',
  'agence-de-voyages',
  'cafe',
  'packages',
  'visite-guidee'
  
]

export const EXCLUDED_TAGS = ['hotel', 'agence-de-voyages', 'activite', 'transport', 'restaurant', "evenement", 'packages',
'visite-guidee','hotel-connect', 'circuit-decouvertes']

export const EXCLUDED_TAGS_SUB_MENU_EXPLORE = ['hotel', 'agence-de-voyages', 'transport', "evenement", 'packages',
'visite-guidee', 'hotel-connect', 'circuit-decouvertes']

//what to exclude from TRIP PLanner
export const TRIP_PLANNER_ARRAY = [
  "activite",
  "restaurant",
  "evenement",
  "transport",
  'hotel',
  'agence-de-voyages',
  'cafe',
  'packages',
  'visite-guidee',
  'hotel-connect',
  'circuit-decouvertes'
];

export const CONNECT2021_ARRAY = ['hotel-connect', 'circuit-decouvertes']

export const CONTACT_ARRAY = ["activite"];
export const SUPPORTED_LOCALS = [ "fr", "es", "en", "ar" /*, 'zh'*/];
export const MAPBOX_ACCESS_TOKEN = {
  accessToken:
    "pk.eyJ1IjoiY29tZXBpYyIsImEiOiJjazB5bTQ1emEwZnFjM2lwaGUzcTA4Y2d6In0.uNqxPdD-ijZeEZd5RxN_oA",
};

export const JSON_HEADERS = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
};

export const SLDIER_SETTINGS = {
  adaptiveHeight: false,
  variableWidth: false,
  dots: false,
  infinite: false,
  speed: 1000,
  lazyLoad: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1680,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: false,
      },
    },

    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const CITIES = [
  {
    label: "tanger",
    name: "Tanger",
  },
  {
    label: "tetouan",
    name: "Tétouan",
  },
  {
    label: "alhoceima",
    name: "Al Hoceima",
  },
  {
    label: "chefchaen",
    name: "Chefchaen",
  },
  {
    label: "asilah",
    name: "Asilah",
  },
];

export const PLACES_DATA_SKELETON = [
  {
    featured: false,
    subtitle: {
      en: null,
      fr: null,
      es: null,
      ar: null,
      zh: null,
    },
    title: {
      en: null,
      fr: null,
      es: null,
      ar: null,
      zh: null,
    },
    description: {
      en: null,
      fr: null,
      es: null,
      ar: null,
      zh: null,
    },
    tags: [
      {
        id: null,
        slug: null,
      },
    ],
    media: [{ url: null, size: null }],
    location: {
      type: "Point",
      coordinates: [],
    },
    id: null,
    status: "draft",
    contact: {
      email: null,
      phone: null,
      url: null,
      address: {
        street_address: null,
        postal_code: null,
        city: null,
      },
    },
    misc: {
      minVisitDuration: null,
      cost: null,
      bias: null,
      HotelRating: null,
      EventStartDate: null,
      EventEndDate: null,
      priceRange: null,
      suitableForDisabled: null,
      wifi: null,
      alcool: null,
    },
    openingHours: {
      0: {
        opens: null,
        closes: null,
        is24: null,
      },
      1: {
        opens: null,
        closes: null,
        is24: null,
      },
      2: {
        opens: null,
        closes: null,
        is24: null,
      },
      3: {
        opens: null,
        closes: null,
        is24: null,
      },
      4: {
        opens: null,
        closes: null,
        is24: null,
      },
      5: {
        opens: null,
        closes: null,
        is24: null,
      },
      6: {
        opens: null,
        closes: null,
        is24: null,
      },
      exceptions: null,
    },
  },
];
