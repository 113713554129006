import React, {useState, useEffect} from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import Container from './Container'
import { useTranslation } from 'react-i18next';
import MegaMenuContainer from './MegaMenuContainer'
import {EXCLUDED_TAGS_SUB_MENU_EXPLORE} from '../constants'


export default function SubMenuExplore({ showMenu, setShowMenu, local, tags, cities }) {
    const { t } = useTranslation();
  const [filtredTags, setFiltredTags] = useState(tags)
  useEffect(() => {
    if (tags && Array.isArray(tags)) {
      const filtertags = tags.filter(t => EXCLUDED_TAGS_SUB_MENU_EXPLORE.indexOf(t.slug) === -1)
      setFiltredTags(filtertags)
    }
  },[tags])
    return (
      <MegaMenuContainer showMenu={showMenu} setShowMenu={setShowMenu}>
        <Container>
          <Row>
            <Col md={12}>
              <div className="mega-menu-modal-left-content">
                            <div className="mega-menu-modal-sb-title">{t('explore')}</div>
                            {
                                filtredTags.map((item, k) => (
                                    <Link key={'explore-items'+k} to={'/'+local+'/explore/'+item.slug} onClick={()=>setShowMenu(false)}>
                                    <div >
                                        <h1>{item.name[local]}</h1>
                                    </div>
                                    </Link>
                                   
                                )
                                    
                                )
                            }
                
              </div>
                    </Col>
                    
                    <Col md={8}>
              <div className="mega-menu-modal-left-content">
                            <div className="mega-menu-modal-sb-title">{t('destination')}</div>

                {
                    cities.map((item, k) => (
                      <Link key={'destination-items'+k} to={'/'+local+'/destination/'+item.slug} onClick={()=>setShowMenu(false)}>
                      <div >
                          <h1>{item.title[local]}</h1>
                      </div>
                      </Link>
                     
                  )
                      
                  )
                }
                
                
              </div>
                    </Col>
                    
          </Row>
        </Container>
      </MegaMenuContainer>
    );
}