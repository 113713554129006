import React from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Container from './Container'
import MegaMenuContainer from './MegaMenuContainer'

const exploreItems = [
    {
        title: 'tripPlanner',
        link: 'trip-planner'
    },
  
    {
        title: 'guideMap',
        link: 'guide-maps'
    },
    {
        title: 'hotels',
        link: 'explore/hotel'
    },
    {
        title: 'transport',
        link: 'explore/transport'
    },
    {
        title: 'travelAgencies',
        link: 'explore/agence-de-voyages'
    }
]

const destinationsItems = [
    {
        title: 'howToGetThere',
        link: 'getting-to-tangier'
    },
  {
      title: 'tripIdea',
      link: 'trip-ideas'
  },
  {
      title: 'praticalInfos',
      link: 'practical-infos'
  },
  {
      title: 'visasInfo',
      link: 'visa'
  }
 
]

export default function SubMenuExplore({ showMenu, setShowMenu, local }) {
    const { t } = useTranslation();

    return (
      <MegaMenuContainer showMenu={showMenu} setShowMenu={setShowMenu}>
        <Container>
          <Row>
            <Col md={12}>
              <div className="mega-menu-modal-left-content">
                            <div className="mega-menu-modal-sb-title">{t('plan')}</div>
                            {
                                exploreItems.map((item, k) => (
                                    <Link key={'explore-items'+k} to={'/'+local+'/'+item.link} onClick={()=>setShowMenu(false)}>
                                    <div >
                                        <h1>{t(item.title)}</h1>
                                    </div>
                                    </Link>
                                   
                                ))

                               
                            }

                            <a href='https://visittanger.app' onClick={()=>setShowMenu(false)} target='blank'>
                            <div >
                                <h1>{t('mobileApps')}</h1>
                            </div>
                            </a>
                
              </div>
                    </Col>
                    
                    <Col md={12}>
              <div className="mega-menu-modal-left-content">
                            <div className="mega-menu-modal-sb-title">{t('howToGetThere')}</div>

                {
                  destinationsItems.map((item, k) => (
                      <Link key={'destination-items'+k} to={'/'+local+'/'+item.link} onClick={()=>setShowMenu(false)}>
                      <div >
                          <h1>{t(item.title)}</h1>
                      </div>
                      </Link>
                     
                  )
                      
                  )
                }
                
                
              </div>
                    </Col>
                   
          </Row>
        </Container>
      </MegaMenuContainer>
    );
}