import Places from './places'
import Quotes from './quotes'
import Tags from './tags'
import Articles from './articles'
import Filters from './filters'
import Favorites from './favorites'
import Cities from './cities'
import Instagram from './instagram'
import Experiences from './experiences'
import Tour from './tour'
import Mice from './mice'
import Local from './local'
import User from './user'
import Stats from './stats'

export default {
    Places,
    Tags,
    Filters,
    Favorites,
    Cities,
    Instagram,
    Experiences,
    Tour,
    Quotes,
    Mice,
    Articles,
    Local,
    User,
    Stats
}