import React, { useState, useEffect } from 'react'
import SliderWithTitle from "../../components/sliderWithTitle"

export default function Statistics({ isAdherent, title, subtitle, places, local }) {
    const [stats, setStats] = useState([])
    useEffect(() => {
        if (places && Array.isArray(places)){
            const filtredArticles = places.filter(a=>a.tags.indexOf('publications') > -1)
            setStats(filtredArticles)
        }
    },[places])
    return (
      <SliderWithTitle
            isAdherent={isAdherent}
            key={100+'statistics-SliderWithTitle-Explore'}
            title={title}
            subtitle={subtitle}
            places={stats.map(s=>{return {...s, link: `/${local}/adherent/publications_studies/${s._id}`, attachment: s.attachment}})}
            local={local}
            height={'370px'}
      />
    );
}