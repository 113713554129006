import React, { Suspense, useEffect } from "react"
import { connect } from 'react-redux'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next';
import { setFilters } from '../../actions/filters'
import history from '../../history'
import SearchSection from './SearchSection'
import HomeSeo from './HomeSeo'
const Planning = React.lazy(() => import('./Planning'));
const Visiting = React.lazy(() => import('./Visiting'));


function App({ places, tags, cities, filters, local, setFilters }) {
  const {  i18n  } = useTranslation();
  useEffect(() => {
    history.push('/' + local + '/')
    i18n.changeLanguage(local);
  }, [local, i18n])

  useEffect(() => {
    setFilters({ ...filters, layout: 'visitors' })
    // eslint-disable-next-line
  },[setFilters])
  
  return (
    <div>
      <HomeSeo />
      {places && Array.isArray(places) && tags && (
        <div>
          {
            <SearchSection places={places} tags={tags} cities={cities} />
          }
         
          {filters.stade === "visiting" ? (
            <Suspense fallback={<Skeleton active />}>
              <Visiting />
            </Suspense>
          ) : (
            <Suspense fallback={<Skeleton active />}>
              <Planning />
            </Suspense>
          )}
        </div>
      )}
    </div>
  );
}


const mapStateToProps = state => {
  return {
    ...state.Places,
    ...state.Tags,
    ...state.Cities,
    ...state.Instagram,
    ...state.Experiences,
    ...state.Filters,
    ...state.Favorites,
    ...state.Local
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFilters: filter=>dispatch(setFilters(filter))
}
}

const appConnect = connect(mapStateToProps, mapDispatchToProps)(App)

export default appConnect

