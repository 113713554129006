import React from 'react'
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next'


export default function HomeSeo({ local, title }) {
    const { t } = useTranslation()
    return (
        <Helmet>
            <title>{(title || t('adherent').toUpperCase()) +'  -  ' +t('seoHomeTitle')}</title>
        </Helmet>
    )
}