import React, { useState } from "react";
import { connect } from "react-redux";
import { message, Badge } from "antd";
import useScroll from "../hooks/useScroll";
import isMobile from "ismobilejs";
import SubMenuExplore from "./SubMenuExplore.js";
import SubMenuMobile from "./SubMenuMobile.js";
import MobileMenu from "./MobileMenu";

import SubMenuPlan from "./SubMenuPlan.js";
import { Link } from "react-router-dom";
import logo from "../images/logo_colors.png";
import search from "../images/search-dark.png";
import toolsIcon from "../images/tools.svg";
import ressources from "../images/ressources.svg";
import publicationIcon from "../images/article.svg";
import "../css/MainHeaderAdherents.css";

const RenduMenuElement = ({ menu }) => {
  return (
    <div
      className={menu.isSpecial ? "Main-menu-item special" : "Main-menu-item"}
      style={{
        backgroundColor: menu.isSpecial ? "#F44336" : "",
        borderBottom: menu.isSpecial ? "3px solid var(--vt-blue)" : "",
      }}
    >
      <div className="icon-holder">
        {menu.badge && menu.badge > 0 ? (
          <Badge count={menu.badge}>
            <img src={menu.icon} className="menu-icon-image" alt={menu.title} />
          </Badge>
        ) : (
          <img src={menu.icon} className="menu-icon-image" alt={menu.title} />
        )}
      </div>
      <span className="Main-menu-text">{menu.title}</span>
    </div>
  );
};

const RenderMenuItem = ({ menu, setShowMenu }) => {
  if (menu.disabled) {
    return (
      <div
        className="Main-menu-item"
        style={{ opacity: 0.3 }}
        onClick={() =>
          message.error("You have to add a least one place to Quote")
        }
      >
        <RenduMenuElement menu={menu} />
      </div>
    );
  }
  if (menu.link) {
    return (
      <Link to={menu.link} className="Main-menu-item">
        <RenduMenuElement menu={menu} />
      </Link>
    );
  } else {
    return (
      <div className="Main-menu-item" onClick={() => setShowMenu(menu.title)}>
        <RenduMenuElement menu={menu} />
      </div>
    );
  }
};

function MainHeader({ quotes, instagram, local }) {
  const { scrollDirection, scrollY } = useScroll();
  const [showMenu, setShowMenu] = useState(false);

  const menuItems = [
    {
      title: "Publications",
      icon: publicationIcon,
      link:'/'+local+'/adherent'
    },
    {
      title: "Outils",
      icon: toolsIcon,
      link:'/'+local+'/adherent/tools'
    },
    {
      title: "Ressources",
      icon: ressources,
    },
  ];

  return (
    <div>
      <header
        className={
          scrollDirection === "up" && scrollY > 1800 && !isMobile().phone
            ? "App-header-classic-adherents App-header-classic-adherents-fixed"
            : isMobile().phone
            ? "App-header-classic-adherents App-header-classic-adherents-mobile"
            : "App-header-classic-adherents"
        }
      >
        <div className="App-wrapper">
          <div className="center-image">
            <Link to={`/${local}/adherent`}>
              <img src={logo} className="App-logo-white" alt="App-logo-white" />

             
            </Link>
          </div>
          <MobileMenu color={"black"} setShowMenu={setShowMenu} />
          <div style={{ width: "100%", zIndex: 999999 }}>
            <div className="Main-menu-wrapper">
              {menuItems.map((m, k) => (
                <RenderMenuItem
                  setShowMenu={setShowMenu}
                  menu={m}
                  key={"menu-100" + k}
                />
              ))}
            </div>
          </div>

          <div className="menu-icons-right">
            <div>
              <Link to={`/${local}/search`}>
                <img src={search} className="header-icons" alt="search" />
              </Link>
            </div>
          </div>
        </div>
      </header>

      {showMenu === "Explore" && (
        <SubMenuExplore
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          instagram={instagram}
          local={local}
        />
      )}

      {showMenu === "Plan" && (
        <SubMenuPlan
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          instagram={instagram}
          local={local}
        />
      )}

      {showMenu === "Mobile" && (
        <SubMenuMobile
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          instagram={instagram}
          local={local}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.Favorites,
    ...state.Instagram,
    ...state.Local,
    ...state.Mice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const appConnect = connect(mapStateToProps, mapDispatchToProps)(MainHeader);

export default appConnect;
