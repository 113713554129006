export const SET_TOUR = 'SET_TOUR'
export const SET_STARTING_POINT = 'SET_STARTING_POINT'
export const TOUR_ADD_PLACE = 'TOUR_ADD_PLACE'
export const TOUR_REMOVE_PLACE = 'TOUR_REMOVE_PLACE'
export const SAVE_TOUR = 'SAVE_TOUR'

export function setTour(tour) {
   
    return {
        type: SET_TOUR,
        payload: tour
    }
}

export function archiveTour(tour) {
    //tour = {startingPoint:[], places:[]}
   
    return {
        type: SAVE_TOUR,
        payload: tour
    }
}

export function setStartingPoint(point) {
   
    return {
        type: SET_STARTING_POINT,
        payload: point
    }
}

export function tourAddPlace(day, place) {
   
    return {
        type: TOUR_ADD_PLACE,
        payload: {day, place}
    }
}



export function tourRemovePlace(day, id) {
   
    return {
        type: TOUR_REMOVE_PLACE,
        payload: {day, id}
    }
}
