import React, {useState, useEffect} from 'react'
import HeroBackground from "../../components/HeroBackground"
import Container from '../../components/Container'
import Spacer from '../../components/Spacer'
import {shuffle} from '../../helpers'
import HomeCustomizeForm from '../../components/HomeCustomizeForm'
import ScrollArrow from '../../components/ScrollArrow'

function SearchSection({places}) {
  
  const [heroBg, setHeroBg] = useState()
  const [isPersonnalisationDone, setIsPersonnalisationDone] = useState(false)
  const [hide, setHide] = useState('')

 

  useEffect(() => {
    if (isPersonnalisationDone) {
      const x = setTimeout(function () { setHide('hide') }, 1000)
      return function cleaup() {
        clearTimeout(x)
      }
    }
   
  },[isPersonnalisationDone])
  
  const handleSectionVisibility = (state) => {
    setIsPersonnalisationDone(state)
  }

  useEffect(() => {
    if (places) {
      const featuredPlaces = shuffle(places.filter(p => p.featured))
      if (Array.isArray(featuredPlaces)) {
        setHeroBg({ ...featuredPlaces[0] })
      } 
    }
   
    }, [setHeroBg, places])

   
    
    return (
      <div className={isPersonnalisationDone ? 'hide-hero-bg '+hide : ''}>
        <HeroBackground place={heroBg} >
          <Spacer top='77px'/>
          <Container>
          <HomeCustomizeForm handleSectionVisibility={handleSectionVisibility}/>
          </Container>
        
        </HeroBackground>

        <ScrollArrow />
      </div>
    );
}

export default SearchSection