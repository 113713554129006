import { SET_ARTICLES } from '../actions/articles'

const initState = {
    articles: []
}
  
export default function articlesReducer (state = initState, action) {
    switch (action.type) {
      case SET_ARTICLES:
            return {
              articles: action.payload
        }
      default:
        return state
    }
  }
  
