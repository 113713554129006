import React, { useState, useEffect } from "react";
import { orderByDistance } from "geolib";
import { Icon } from "antd";
import { useTranslation } from 'react-i18next';
import { usePosition } from "../hooks/usePosition";
import BigTitle from "./BigTitle";
import Container from "./Container";
import Spacer from "./Spacer";
import ImageSlider from "./ImageSlider";
import "../css/Nearby.css";

const voirCats = ["histoire", "nature", "archeologie", "art_litterature"];
const faireCats = ["activite", "evenement"];
const mangerEtBoireCats = ["restaurant", "nightlife"];

function findCommonElements(arr1, arr2) {
  return arr1.some((item) => arr2.includes(item));
}

export default function Nearby({
  tags,
  places,
  nearbyPosition,
  clearTheme,
  local,
  isMice,
  user,
}) {
  
  const { t } = useTranslation()
  const [selectedCat, setSelectedCats] = useState({ id: 0, tags: ['tous'] });
  const [selectedPlaces, setSelectedPlaces] = useState(places);
  const { latitude, longitude } = usePosition();

  useEffect(() => {
    if (selectedCat.tags.length > 0) {
      const placesByTags = selectedCat.tags[0] === 'tous' ? places : places.filter((place) => {
        const slugs = place.tags.map((p) => p.slug);
        return findCommonElements(slugs, selectedCat.tags);
      });

      if (nearbyPosition && nearbyPosition[0] && nearbyPosition[1]) {
        const selectedPlacesOrderedByDistance = orderByDistance(
          {
            latitude: nearbyPosition[1],
            longitude: nearbyPosition[0],
          },
          placesByTags.map((p) => {
            return {
              latitude: p.location.coordinates[1],
              longitude: p.location.coordinates[0],
              ...p,
            };
          })
        );
        setSelectedPlaces(selectedPlacesOrderedByDistance);
      } else if (latitude && longitude && !nearbyPosition) {
        const selectedPlacesOrderedByDistance = orderByDistance(
          {
            latitude,
            longitude,
          },
          placesByTags.map((p) => {
            return {
              latitude: p.location.coordinates[1],
              longitude: p.location.coordinates[0],
              ...p,
            };
          })
        );
        setSelectedPlaces(selectedPlacesOrderedByDistance);
      } else if (
        !latitude &&
        !longitude &&
        !nearbyPosition &&
        user.latitude &&
        user.longitude
      ) {
        const selectedPlacesOrderedByDistance = orderByDistance(
          {
            latitude: user.latitude,
            longitude: user.longitude,
          },
          placesByTags.map((p) => {
            return {
              latitude: p.location.coordinates[1],
              longitude: p.location.coordinates[0],
              ...p,
            };
          })
        );
        setSelectedPlaces(selectedPlacesOrderedByDistance);
      } else {
        setSelectedPlaces(placesByTags);
      }
    }
  }, [places, selectedCat, latitude, longitude, nearbyPosition, user]);

  return (
    <div id="Nearby-component">
      {places && Array.isArray(places) && tags && Array.isArray(tags) && (
        <Container>
          <div id="Tour-builder">
            <BigTitle clearTheme={clearTheme} title={t('nearby')} />

            <p style={{ color: "#ff5722" }}>
              {latitude || nearbyPosition ? (
                ""
              ) : (
                <span>
                    <Icon type="alert" /> {t('gpsOff')}
                </span>
              )}
            </p>
            <div className="Nearby-wrapper">
              
            <div
            className={
              selectedCat.id === 0
                ? "Nearby-filters-selected"
                : clearTheme
                ? "Nearby-filters-clear"
                : "Nearby-filters"
            }
            onClick={() => setSelectedCats({ id: 0, tags: ['tous'] })}
          >
                <h3>{t('all')}</h3>
              </div>
              
              <div
                className={
                  selectedCat.id === 1
                    ? "Nearby-filters-selected"
                    : clearTheme
                    ? "Nearby-filters-clear"
                    : "Nearby-filters"
                }
                onClick={() => setSelectedCats({ id: 1, tags: voirCats })}
              >
                <h3>{t('see')}</h3>
              </div>
              <div
                className={
                  selectedCat.id === 2
                    ? "Nearby-filters-selected"
                    : clearTheme
                    ? "Nearby-filters-clear"
                    : "Nearby-filters"
                }
                onClick={() => setSelectedCats({ id: 2, tags: faireCats })}
              >
                <h3>{t('do')}</h3>
              </div>
              <div
                className={
                  selectedCat.id === 3
                    ? "Nearby-filters-selected"
                    : clearTheme
                    ? "Nearby-filters-clear"
                    : "Nearby-filters"
                }
                onClick={() =>
                  setSelectedCats({ id: 3, tags: mangerEtBoireCats })
                }
              >
                <h3>{t('eat')}</h3>
              </div>
            </div>

            <Spacer top="67px" />
            <ImageSlider
              clearTheme={clearTheme}
              data={selectedPlaces}
              position={{
                latitude: nearbyPosition ? nearbyPosition[1] : latitude ? latitude : user.latitude,
                longitude: nearbyPosition ? nearbyPosition[0] : longitude ? longitude : user.longitude,
              }}
              height="350px"
              local={local}
              isMice={isMice}
            />
          </div>
        </Container>
      )}

      <Spacer bottom="100px" />
    </div>
  );
}
