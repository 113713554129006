import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { Input, Button, message, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next'
import Spacer from '../../components/Spacer'
import {getSafe, validateEmail} from '../../helpers'
import { fetchPostQuote } from '../../actions/quotes'
import '../../css/QuotesContainer.css'

const { RangePicker } = DatePicker;

function QuoteContainer({ fetchPostQuote, deals, route, places, local, placeId }) {
    const { t } = useTranslation()

    const [routeTo, setRouteTo] = useState()
    const [currentPlace, setCurrentPlace] = useState({})
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [startConvoDetails, setStartConvoDetails] = useState({ from: null, to: null, message: '_', dealId: routeTo, misc:{} })
    
    useEffect(() => {
        const thePlace = (route && route.match && route.match.params) ? route.match.params.to : placeId
        setRouteTo(thePlace)
        setStartConvoDetails({ ...startConvoDetails, dealId: thePlace })
        // eslint-disable-next-line
    }, [route, placeId])
    

    useEffect(() => {
        if (routeTo && Array.isArray(deals)) {
            const theDeal = (deals.filter(d => d._id === routeTo)[0]) ? deals.filter(d => d._id === routeTo)[0] : places.filter(d => d._id === routeTo)[0]
            setCurrentPlace(theDeal)
        }
    }, [routeTo, deals, places])
    
    useEffect(() => {
        if (currentPlace && currentPlace._id) {
            setStartConvoDetails({...startConvoDetails, to: getSafe(()=>currentPlace.contact.email), dealName:getSafe(()=>currentPlace.title[local])})
        }
        // eslint-disable-next-line
    },[currentPlace])

    function startConvo(data) {
        if (data && data.from && data.to) {
            fetchPostQuote(data)
        }
    }

    return (
        <div style={{ 'position': 'relative' }}>
            
            {
                routeTo && !showConfirmation && 
                <div >
                  
                <RangePicker
                onChange={(_, date) => {
                    setStartConvoDetails({ ...startConvoDetails,  misc: {
                        ...startConvoDetails.misc,
                        from_date: date[0],
                        to_date: date[1]
                    } })
              }}
                style={{ width: '100%' }} placeholder={['Arrivée', 'Départ']} />
                  
                <Input placeholder={t('yourName')+'*'} 
                        onChange={(e) => setStartConvoDetails({
                            ...startConvoDetails,
                            misc: {
                                ...startConvoDetails.misc,
                                first_name: e.target.value
                            }
                        })} />
                    
                        <Input placeholder={t('phoneNumber')} 
                        onChange={(e) => setStartConvoDetails({
                            ...startConvoDetails,
                            misc: {
                                ...startConvoDetails.misc,
                                phone: e.target.value
                            }
                        })} /> 
                    
                    <Input placeholder={'Nombre d\'adulte'} 
                        type={'number'}
                        onChange={(e) => setStartConvoDetails({
                            ...startConvoDetails,
                            misc: {
                                ...startConvoDetails.misc,
                                adultsNumber: e.target.value
                            }
                        })} /> 
                    
                    <Input placeholder={"Nombre d'enfant"} 
                    type={'number'}
                        onChange={(e) => setStartConvoDetails({
                            ...startConvoDetails,
                            misc: {
                                ...startConvoDetails.misc,
                                childrenNumber: e.target.value
                            }
                        })} /> 
                    
                    <Input placeholder={t('yourEmail')+'*'} type='email'
                        onChange={(e) => setStartConvoDetails({ ...startConvoDetails, from: e.target.value })} />
                    
                    

                    <Spacer top='10px' />
                 
                    <Spacer top='10px' />
                    <Button type='primary' onClick={
                        () => {
                            if (!startConvoDetails.from ||  !validateEmail(startConvoDetails.from)) {
                                return message.error('Veuillez remplir tous les champs')
                            }
                            startConvo(startConvoDetails)
                            setShowConfirmation(true)
                        }
                    }>{t('sendRequest')}</Button>
                
                </div>
            }

            {showConfirmation && 
                (<div >
                  
                    <h1>Merci!</h1>
                    <p>Vous allez recevoir une notification par email avec un lien pour faire le suivi de votre demande, vous pouvez à tout moment cliquer sur le lien reçu afin d'ajouter des précisions et commentaires</p>
                
                </div>
                )
            }
           
        </div>
    )
}

const mapStateToProps = state => {
    return {
        ...state.Quotes,
        ...state.Places,
        ...state.Local
   }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchPostQuote: (body)=> dispatch(fetchPostQuote(body))
    }
}
  
const appConnect = connect(mapStateToProps, mapDispatchToProps)(QuoteContainer)

export default appConnect