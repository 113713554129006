import React, { useEffect } from "react";
import { connect } from "react-redux";
import i18n from '../i18n'
import { fetchSetPlaces } from "../actions/places";
import { fetchSetTags } from "../actions/tags";
import { fetchSetArticles } from "../actions/articles";
import { fetchSetCities } from "../actions/cities";
import { fetchSetExperiences } from "../actions/experiences";
import { fetchSetMices } from "../actions/mice";
import { fetchsetUserDetails } from "../actions/user";
import { setLocal } from "../actions/local";
import {fetchSetPlacesStats, fetchSetToursStats} from '../actions/stats'
import { supportedLocal } from "../helpers";
import { fetchInstagrams } from "../actions/instagram";
import Layout from "./Layout";
import LayoutMice from "./LayoutMice";
import LayoutAdherents from "./LayoutAdherents";
import Slide from "react-reveal/Slide";

function LayoutComponent({
  filters,
  children,
  fetchSetPlaces,
  fetchSetTags,
  fetchSetCities,
  setLocal,
  fetchSetMices,
  fetchSetExperiences,
  fetchSetArticles,
  local,
  fetchsetUserDetails,
  fetchInstagrams,
  fetchSetPlacesStats,
  fetchSetToursStats
}) {


  useEffect(() => {
    if (local) {
      i18n.changeLanguage(local)
    }
  },[local])

  useEffect(() => {
    fetchSetPlaces();
  }, [fetchSetPlaces]);

  useEffect(() => {
    if (!local) {
      setLocal(supportedLocal());
    }
  }, [setLocal, local]);

  useEffect(() => {
    fetchSetTags();
  }, [fetchSetTags]);

  useEffect(() => {
    fetchSetCities();
  }, [fetchSetCities]);

  useEffect(() => {
    //fetchInstagrams()
  }, []);

  useEffect(() => {
    fetchSetExperiences();
  }, [fetchSetExperiences]);

  useEffect(() => {
    fetchSetMices();
  }, [fetchSetMices]);

  useEffect(() => {
    fetchSetArticles();
  }, [fetchSetArticles]);
    
  useEffect(() => {
    fetchsetUserDetails();
  }, [fetchsetUserDetails]);

  useEffect(() => {
    fetchSetPlacesStats();
    fetchSetToursStats()
  }, [fetchSetPlacesStats, fetchSetToursStats]);

  return (
    <div>
      {(filters && filters.layout && filters.layout === "mice") ? (
        <Slide top>
          <LayoutMice> {children}</LayoutMice>
        </Slide>
      ) : (filters && filters.layout && filters.layout === "adherents") ? (
        <Slide top>
          <LayoutAdherents> {children}</LayoutAdherents>
          </Slide>) : <Layout> {children}</Layout>
      }
     
      
       
  
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.Places,
    ...state.Tags,
    ...state.Filters,
    ...state.Instagram,
    ...state.Local,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSetPlaces: () => dispatch(fetchSetPlaces()),
    fetchSetTags: () => dispatch(fetchSetTags()),
    fetchSetCities: () => dispatch(fetchSetCities()),
    fetchInstagrams: () => dispatch(fetchInstagrams()),
    fetchSetExperiences: () => dispatch(fetchSetExperiences()),
    fetchSetMices: () => dispatch(fetchSetMices()),
    fetchSetArticles: () => dispatch(fetchSetArticles()),
    setLocal: (local) => dispatch(setLocal(local)),
    fetchsetUserDetails: () => dispatch(fetchsetUserDetails()),
    fetchSetPlacesStats: () => dispatch(fetchSetPlacesStats()),
    fetchSetToursStats:() => dispatch(fetchSetToursStats())
  };
};

const appConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutComponent);

export default appConnect;
