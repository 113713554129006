import React from 'react'
import Lottie from 'react-lottie';
import Spacer from './Spacer'
import { Button } from 'antd'
import * as animationData from '../animations/error.json'

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const size = 300


export default function ErrorFallBack() {
    return (
      <div style={{textAlign:'center'}}>
            <Lottie options={defaultOptions} height={size} width={size} />
            <Spacer top={'40px'} />
            <div> <h1>Un problème est survenu </h1></div>
            <Spacer top={'20px'} />
            <Button onClick={()=>window.location.reload()}>Recharger la page</Button>
      </div>
    );



}

