import React from 'react'
import {Icon} from 'antd'

export default function ImageCaption({title, relative, size}) {
    return (
        <div>
            {
                relative ? <Caption title={title} size={size} /> :
               
                    <div style={{ position: 'absolute', bottom: '50px', right: '20px'}}>
                        
                       
                        <Caption title={title} size={size} />
                        </div>
                       
            }
      
     
        </div>
    )
}

const Caption = ({ title, size }) => {
    return (
        <p style={{ color: 'white', fontWeight: 300, fontSize: size || '16px', padding: '5px' }}><Icon type="info-circle" />  {title}</p>

    )
}