import React from 'react'
import { Icon } from 'antd'
import { Link } from 'react-router-dom'
import useHover from '../hooks/useHover'
import SocialIcons from './SocialIcons'
import isMobile from 'ismobilejs'
import '../css/BigTitle.css'

const Title = ({ title, isHovered, clearTheme }) => (
  <h1
    style={{
      fontSize: isMobile().phone ? "24px": "34px",
      letterSpacing: ".1px",
      margin: 0,
      color: clearTheme ? 'white' : "var(--vt-text-color-dark)",
      fontFamily: "var(--vt-font-black)",
      cursor: "pointer"
    }}
  >
    {title}{" "}
    <Icon
      type="right"
      style={{
        fontSize: "18px",
        paddingLeft: "20px",
        color: "var(--vt-dark)",
        display: isHovered ? "" : "none"
      }}
      className="big-title-icon-animation"
    />
  </h1>
);

export default function BigTitle({ title, subtitle, social, link, clearTheme }) {
    const [hoverRef, isHovered] = useHover()

    return (
     
      <div ref={hoverRef}>
        {
          link ?  <Link to={link || '#'}>
            <Title title={title} isHovered={isHovered} clearTheme={clearTheme} />
        </Link> : <Title title={title} clearTheme={clearTheme} />
        }
      
            <p style={{ fontSize:'15px', margin:0, color:"var(--vt-text-color-dark)"}}>
           {subtitle}
        </p>
        
        {
          social &&  <div style={{maxWidth:'100px', margin:0}} >
          <SocialIcons />
          </div>
          }
              
        </div>
     
    );
}