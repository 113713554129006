import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {Link} from 'react-router-dom'
import { setFilters } from '../actions/filters'
import { useTranslation } from 'react-i18next';

import {archiveTour } from '../actions/tour'
import { Tag,  Button, Radio, DatePicker } from "antd";
import Spacer from './Spacer'
import { getSafe, citySlugToName, feedBi } from '../helpers'
import { TRIP_PLANNER_ARRAY } from '../constants'

import '../css/HomeCustomizeForm.css'

const { RangePicker} = DatePicker
const { CheckableTag } = Tag;

function HomeCustomizeForm({ small, resetArchive, tags, cities, filters, setFilters, handleSectionVisibility, tripPlanner, archive, noButtons, local, explore, user}) {
  const [loading, setLoading] = useState(false)
  const [filteredTags, setFiltreredTags] = useState(tags)
  const {  t  } = useTranslation();


  useEffect(() => {
  
      const newTags = tags.filter(t => TRIP_PLANNER_ARRAY.indexOf(t.slug) === -1)
      setFiltreredTags(newTags)
  
  },[tags, tripPlanner])

    function handleTags(tag, checked) {
        const nextSelectedTags = checked ? [...filters.tags, tag] : filters.tags.filter(t => t.slug !== tag.slug);
        setFilters({tags:nextSelectedTags})
        
    }

    function handleCities(tag, checked) {
      const nextSelectedTags = checked ? [...filters.cities, tag] : filters.cities.filter(t => t !== tag);
      let oldDates = filters.dates
      let newDates = {} // remove old dates if city is deselected
     
      nextSelectedTags.forEach(ville => {
        if (oldDates[ville]) {
          newDates[ville] = oldDates[ville]
        }
        
      })
        setFilters({cities:nextSelectedTags, dates: newDates})
      
  }

  function handleDates(dates) {
    setFilters({dates:{...filters.dates, ...dates}})
  }
  
  function handleStade(e) {
    setFilters({stade:e.target.value})
  }
  

  
  return (
    <div>
      <div className={small ? 'Home-customize-form Home-customize-form-small' : "Home-customize-form"}>
        {!tripPlanner && <h1>{t("customizePage")}</h1>}
       
        <p>{t("where")}</p>
        {cities.map(city => (
          <CheckableTag
            key={city.slug}
            checked={filters.cities.indexOf(city.slug) > -1}
            onChange={checked => handleCities(city.slug, checked)}
          >
            {city.title[local]}
          </CheckableTag>
        ))}
        <Spacer bottom="30px" />
        {
          !explore && <div> <p>{t("what")}</p>
          {filteredTags.map(tag => (
            <CheckableTag
              key={tag.slug}
              checked={filters.tags.map(t => t.slug).indexOf(tag.slug) > -1}
              onChange={checked => handleTags(tag, checked)}
            >
              {tag.name[local]}
            </CheckableTag>
          ))}
          <Spacer bottom="30px" /></div>
      }
       

        {!tripPlanner && !explore && (
          <div>
            <p>{t("stade")}</p>

            <Radio.Group onChange={handleStade} defaultValue={filters.stade}>
              <Radio.Button value="visiting">{t("visiting")}</Radio.Button>
              <Radio.Button value="planning">{t("planning")}</Radio.Button>
            </Radio.Group>
            

            <Spacer bottom="30px" />
            {!noButtons && 
              typeof handleSectionVisibility === "function" && (
                <Button
                  type="danger"
                  onClick={() => {
                    handleSectionVisibility(true);
                  }}
                >
                {t("customize")}
                </Button>
              )}
          </div>
        )}

        {tripPlanner && filters.cities.length > 0 && (
          <div>
            <p>{t("dates")}</p>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {filters.cities.map((city, k) => (
                <RangePicker
                  key={"dates-rp" + k}
                  defaultValue={[
                    getSafe(() => filters.dates[city][0])
                      ? moment(
                          getSafe(() => filters.dates[city][0]),
                          "yyyy-mm-dd"
                        )
                      : null,
                    getSafe(() => filters.dates[city][1])
                      ? moment(
                          getSafe(() => filters.dates[city][1]),
                          "yyyy-mm-dd"
                        )
                      : null
                  ]}
                  placeholder={[citySlugToName(city, cities, local), citySlugToName(city, cities, local)]}
                  onChange={(_, date) => {
                    let out = {};
                    out[city.toLowerCase()] = [...date];
                    if (date[0] && date[1]) {
                      feedBi({
                        ...user,
                        fromDate: date[0],
                        toDate: date[1]
                      });
                    }
                    handleDates(out);
                  }}
                />
              ))}
            </div>
          </div>
        )}
        <Spacer bottom="30px" />
          <div style={{ display: 'flex', justifyContent:'space-between', flexWrap: "wrap"}}>
        {!noButtons && tripPlanner && (
        
          <Link to={`/${local}/trip-planner`}>
            <Button
              type="danger"
              loading={loading}
              onClick={() => {
                resetArchive()
                setLoading(true)
              }}
            >
              {t("buildAnew")}
            </Button>
          </Link>
        )}

        {!noButtons && tripPlanner && archive && archive.length > 0 && (
         
          <Link to={`/${local}/trip-planner/Edit`} >
            <Button
             
              loading={loading}
              onClick={() => setLoading(true)}
            >
            {t("orContinueEditing")}
            </Button>
          </Link>
         
          
        )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
    return {
        ...state.Places,
        ...state.Tags,
        ...state.Cities,
      ...state.Filters,
      ...state.Tour,
      ...state.Local,
      ...state.User
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
      setFilters: (filters) => dispatch(setFilters(filters)),
      resetArchive : () => dispatch(archiveTour([]))
  }
  }
  
  const appConnect = connect(mapStateToProps, mapDispatchToProps)(HomeCustomizeForm)
  
  export default appConnect
  
  