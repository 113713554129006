import {SET_PLACES_STATS} from '../actions/stats'
import {SET_TOURS_STATS} from '../actions/stats'

const initState = {
    placesStats: {},
    tourStats:{}
}
  
export default function placesReducer (state = initState, action) {
    switch (action.type) {
      case SET_PLACES_STATS:
            return {
                ...state,
                placesStats: action.payload,
            
        }
        case SET_TOURS_STATS:
                return {
                    ...state,
                    tourStats: action.payload,
                
            }
          default:
        return state
    }
  }
  