import React, { useState } from 'react'
import { connect } from 'react-redux'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Tag, Icon, Modal } from "antd";
import HomeCustomizeForm from './HomeCustomizeForm'
import '../css/CustomisationHeader.css'
import { diffInDays, citySlugToName } from '../helpers'
const { CheckableTag } = Tag;


function CustomisationHeader({filters,  local, cities}) {

  const [modalVisible, setModalVisible] = useState(false)

  return (
   
    <header className="App-header-customisation" >
      
      <div className="App-wrapper">
    
      <ScrollContainer>
          <div onClick={() => setModalVisible(true)} style={{ width: '100%', textAlign: 'center' }}>
            
             
          {filters.cities && filters.cities.length > 0 && <Icon type="pushpin" />}
          {filters.cities.map(city => (
            <CheckableTag
              key={city}
              checked={true}
              
            >
              {citySlugToName(city, cities, local)}
            </CheckableTag>
        ))}
     
            {filters.tags && filters.tags.length > 0 && <Icon type="tags" style={{paddingLeft:'20px'}}/>}
        {filters.tags.map(tag => (
          <CheckableTag
            key={tag.slug}
            checked={true}
            
          >
            {tag.name[local]}
          </CheckableTag>
        ))}
           
         
          {(filters.fromDate && filters.toDate) && 
            <Icon type="calendar" style={{paddingLeft:'20px'}}/>
          }
          {(filters.fromDate && filters.toDate) && 
            <span className='ant-tag-checkable-checked'>{diffInDays(filters.fromDate, filters.toDate)} Days</span>
          }
 
         
      </div>
      
      </ScrollContainer>
      
     
      
      </div>
      
    <Modal
   
      visible={modalVisible}
      onOk={()=>{setModalVisible(false)}}
        onCancel={() => { setModalVisible(false) }}
        footer={null}
    >
          <HomeCustomizeForm tripPlanner noButtons/>
    </Modal>
      </header>

  );
}


const mapStateToProps = state => {
  return {
    ...state.Filters,
    ...state.Local,
    ...state.Cities
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
   
}
}

const appConnect = connect(mapStateToProps, mapDispatchToProps)(CustomisationHeader)

export default appConnect