import React from 'react'
import { Tabs } from 'antd';
import Container from '../../components/Container'
import BreadCramb from './Breadcramb'
import Spacer from '../../components/Spacer'
import Visa from '../VisaInfo'
import Seo from './PraticalInfoSeo'
const { TabPane } = Tabs;



export default function GettingHere() {
    return (
        <div className='getting-here-container'>
        <Seo />
            <Spacer top='20px' />
            
            <Container>
                <BreadCramb local='fr' />
                <Spacer top='50px' />
            <Tabs defaultActiveKey="1" tabPosition='right'>
            <TabPane tab="التوقيت المحلي" key="1">
                        وقيت غرينيتش. في الصيف، ابتداء من الأحد الأخير من مارس إلى أواخر أكتوبر يتم الانتقال إلى التوقيت الصيفي مع زيادة ساعة واحدة على توقيت غرينيتش. وفي رمضان الذي يصادف مؤخرا فصل الصيف تتم العودة إلى توقيت غرينيتش.
             </TabPane>
            
                    <TabPane tab="المناخ " key="2">
              <p >تقع المنطقة تحت تأثير المناخ المتوسطي.
              درجات الحرارة تكون لطيفة في الشتاء ومعتدلة في الصيف سواء في السواحل أو في المناطق العالية.
              في يناير درجات الحرارة الدنيا نادرا ما تنزل عن درجة الصفر والعليا تبقى ما بين 14 و18 درجة
              وفي الصيف يصير المناخ أكثر حرارة وتغدو درجة الحرارة العليا متأرجحة ما بين 16 درجة و26 درجة. وفي بعض السنوات تبلغ الحرارة العليا من 32 درجة إلى 38 درجة لكنها تكون حرارة استثنائية ونادرة.
               </p>
                        <p>التوقعات المناخية:<br/>الهاتف: 05 22 90 24 22</p>
            </TabPane>
            <TabPane tab="التأشيرات" key="3">
              <Visa />
                    </TabPane>
                    <TabPane tab="اللغات" key="4">
                 <p>اللغات الوطنية هي العربية والأمازيغية.
                 في طنجة، تطوان والحسيمة يُتحدَّثُ بالعديد من اللغات بما في ذلك الإسبانية والفرنسية والإنجليزية.
                 
                 
                 </p>
                    </TabPane>
                    <TabPane tab="العملة " key="5">
                   <p>عملة المغرب هي الدرهم (MAD) وهو ينقسم إلى 100 سنتيم.
                   توجد أوراق من فئة 20 درهما و50 درهما و100 درهم ومائتي درهم، وقطع نقدية من 0,5 درهم، و5 دراهم، ودرهم واحد، ودرهمان، و10 دراهم و10 سنتيمات، و20 سنتيمات...
                   
                   يمكن سحب المبالغ المالية عبر بطاقات الائتمان ودفتر الشيكات في جميع فروع البنوك.
                   يمكن استخدام بطاقات الائتمان في الفنادق الرئيسية الكبرى والمراكز التجارية الكبرى والمطاعم وبعض المحلات التجارية.</p>
                    </TabPane>
                    <TabPane tab="توقيت العمل" key="6">
                    <p>توقيت العمل في الإدارات والمصالح العمومية توقيت مستمر من: الثامنة إلى الرابعة والنصف زوالا.
                    بعض المؤسسات الخاصة تتبع أيضا نفس التوقيت مثل البنوك
                    
                    
                    </p>
                    </TabPane>
                    <TabPane tab="أماكن ممارسة طقوس العبادة في طنجة" key="7">
                    <p>توجد مساجد في الأحياء الرئيسية للمدينة


                    </p>
                        <strong>الكاتدرائية</strong><br />
                        S: حي سيدي بوعبيد طنجة المغرب
                        <br />
                        الهاتف: 05 39 932 762<br /><br />

                        <strong>بيعة شار رفائيل
                        </strong><br />
                        26 شارع باستور، طنجة المغرب

                        <br /><br />
                        
                        <strong>بيعة بن عطار
                        </strong><br />
                        1، زنقة الحرية، طنجة المغرب.

                        <br />

                    </TabPane>
                    <TabPane tab="مستعجلات" key="8">
                        <p><strong>البوليس</strong> الهاتف: 19</p>
                        <p><strong>رجال الوقاية المدنية </strong> الهاتف: 15</p>
                        <p><strong>تأمين الطرق </strong> الهاتف: 177</p>
                        <p><strong>معلومات تلفونية</strong> الهاتف: 160</p>
                        <p><strong>مستشفى محمد الخامس</strong> الهاتف: 05 39 93 42 42 / 05 39 93 24 44 / 05 39 93 10 73</p>
                        <p><strong>العيادة الطبية المنزلية</strong> الهاتف: 05 39 33 33 00</p>
                        <p><strong>الإسعافات الأولية</strong> الهاتف: 05 39 95 40 40</p>
                        <p><strong>الهلال الأحمر المغربي</strong> الهاتف: 05 39 35 20 20 </p>

                  </TabPane>

          </Tabs>
            </Container>
            <Spacer top='100px'/>
      
        </div>
    )
}


