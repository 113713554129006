import React, { useRef } from 'react'
import Slider from 'react-slick'
import { Button } from "antd";
import ImageCard from './ImageCard'
import {getSafe} from '../helpers'

import { SLDIER_SETTINGS, NO_IMAGE } from '../constants'
const ButtonGroup = Button.Group;


export default function ImageSlider({ data, distance, date, height, position, clearTheme, slides, slideWidth, local='en', isMice, isStat }) {
  let newSliderSettings = slides ? {
      ...SLDIER_SETTINGS,
      slidesToShow: slides,
    slidesToScroll: slides, responsive:[]
  } : SLDIER_SETTINGS

  const slideRef = useRef(null);

  const onNext = () => {
    slideRef.current.slickNext();
  };

  const onPrevious = () => {
    slideRef.current.slickPrev();
  };

    
    return (
      <div style={{ maxWidth: "100%" }}>
      <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "-20px",
      }}
    >
      <ButtonGroup>
            <Button
            shape="circle"
         
          size="default"
          icon="left"
          onClick={onPrevious}
          style={nextPrev}
        />
            <Button
            shape="circle"
          
          size="default"
          icon="right"
          onClick={onNext}
          style={nextPrev}
        />
      </ButtonGroup>
    </div>
        <Slider {...newSliderSettings} ref={slideRef}>
          {data.map((poi, k) => (
            <ImageCard
              isStat={isStat}
              isAdherent 
            clearTheme={clearTheme}
              key={'ImageCard-'+k + '+'+getSafe(()=>poi["title"])}
              url={poi.featuredImage ? poi.featuredImage : NO_IMAGE}
              attachment= {poi.attachment}
              title={getSafe(()=>poi["title"])}
              category={ 'd'}
              city={
               getSafe(()=>poi["city"])
              }
              height={height}
              date={date}
              eventStart={getSafe(() => poi.misc.eventStartDate)}
              eventEnds = {getSafe(()=>poi.misc.eventEndDate)}
              _id={poi._id}
              link={poi.link}
              isExperience={poi.isExperience}
            />
          ))}
        </Slider>
      </div>
    );
}


const nextPrev = {
  margin: "5px",
  marginTop: 0,
};
