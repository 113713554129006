import React from 'react'
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next'


export default function HomeSeo({ local }) {
    const { t } = useTranslation()
    return (
        <Helmet>
            <title>{t('seoHomeTitle')}</title>
        </Helmet>
    )
}