import {SET_QUOTES, ADD_TO_QUOTE, SET_QUOTE_META} from '../actions/quotes'

const initState = {
  quotes: [],
  dealId: '',
  misc:{}
}
  
export default function quotesReducer (state = initState, action) {
    switch (action.type) {
      case SET_QUOTES:
        return {
          ...state,
          quotes: action.payload
        }
        case ADD_TO_QUOTE:
        return {
            ...state,
            quotes: [...state.quotes, action.payload]
        }
      
        case SET_QUOTE_META:
          return {
              ...state,
              dealId: action.payload.dealId,
              misc: action.payload.misc
            }
      default:
        return state;
    }
  }
  