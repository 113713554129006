import React from 'react'
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next'


export default function HomeSeo({ title, description }) {
    const { t } = useTranslation()
    return (
        <Helmet>
            <title>{title + '  -  ' + t('seoHomeTitle')}</title>
            {description &&  <meta name="description" content={description} />}
        </Helmet>
    )
}