import React from 'react'
import ImageCaption from './ImageCaption'
import {local} from '../translations'
import {getSafe, cdnImage} from '../helpers'

import '../css/HeroBackground.css'


function PersonalizeYourVisit({ place, children }) {
  return (
    <div>
      {place && place["media"] && (
        <div
          style={{
            backgroundImage: "url(" + getSafe(() => cdnImage(place["media"][0]["url"], true, "w_1200")) + ")",
            animation: ' bigBg-reveal 10s ease'
          }}
          className="Personalize-bg"
        >
         
          <ImageCaption title={getSafe(()=>place.title[local])} />

          {
            children
          }
        </div>
      )}
    </div>
  );
}


/*
const GradiantComponent = () => (
  <div style={{
    position: 'absolute',
    top: '0px', 
    left: 0,
    height: '90%',
    width:'100%',
    background: "linear-gradient(to bottom, var(--vt-blue) 0%,rgba(125,185,232,0) 100%)"

  }} />
)
*/

export default PersonalizeYourVisit