export const SET_INSTAGRAM = 'SET_INSTAGRAM'
const API_PLACES = "https://www.instagram.com/visittangierregion/"

function setInstagram(instagrams) {
    return {
        type: SET_INSTAGRAM,
        payload: instagrams
    }
}


// get places from the server
export function fetchInstagrams() {
    return function (dispatch) {
        return fetchApiGetPlaces()
                .then(function(res){ return res.text(); })
                .then(function (response) {
                    if (response) {
                        let data = response.split("window._sharedData = ")[1].split("</script>")[0];
                        data = JSON.parse(data.substr(0, data.length - 1));
                        data = data.entry_data.ProfilePage || data.entry_data.TagPage || null;
                        if (data === null) {
                            console.error("InstagramFeed: Request error. No data retrieved: ");
                           
                        }else{
                            data = data[0].graphql.user || data[0].graphql.hashtag;
                          
                            if (data.edge_owner_to_timeline_media && data.edge_owner_to_timeline_media.edges) {
                                console.log('data', data.edge_owner_to_timeline_media.edges)
                                dispatch(setInstagram(data.edge_owner_to_timeline_media.edges))
                            }
                          
                        }
                    } else {
                        console.log('Something bad happens. Code: INSTGM01')
                    }
                })
                .catch(e=>console.log('INSTGM02 ',e))
    }
    
}

function fetchApiGetPlaces() {
    console.log('instagram fetch start')
    return fetch(API_PLACES,{
        method: "GET"
    })
}