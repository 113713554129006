import React from 'react'
import { Tabs } from 'antd';
import Container from '../../components/Container'
import BreadCramb from './Breadcramb'
import Spacer from '../../components/Spacer'
import Visa from '../VisaInfo'
import Seo from './PraticalInfoSeo'
const { TabPane } = Tabs;



export default function GettingHere() {
    return (
        <div className='getting-here-container'>
        <Seo />
            <Spacer top='20px' />
            
            <Container>
                <BreadCramb local='fr' />
                <Spacer top='50px' />
            <Tabs defaultActiveKey="1" >
            <TabPane tab="Local time" key="1">
            GMT (summertime from 1/04 to 31/10: +1 hour).
            During the Ramadan we come back to the GMT official time.
            </TabPane>
            <TabPane tab="Weather " key="2">
              <p >The weather of the region is directly influenced by the Mediterranean climate. Temperatures remain mild in winter, mild summer on both, coasts and high altitude. They rarely fall below 0°C in January in heights, and the most frequent maxima of this month fluctuate between 14°C and 18°C.
              In summer, the weather is warming significantly, the most common maximum temperatures in July vary between 16°C and 26°C. peaks of 32°C to 38°C have been recorded a few days a year, but their frequency remains exceptional.
              </p>
                        <p>Weather forecast:<br/>Tel: 05 22 90 24 22</p>
            </TabPane>
            <TabPane tab="Visas" key="3">
              <Visa />
                    </TabPane>
                    <TabPane tab="Languages " key="4">
                 <p>In Tangier, Tetouan and Al Hoceima, several languages including Spanish, French and English are largely spoken.
                 Arabic and Amazigh are the national languages.
                 </p>
                    </TabPane>
                    <TabPane tab="Currency " key="5">
                   <p>The currency in Morocco is the Dirham (Dh), divided into 100 cents. There are bank notes of 20, 50,100 and 200 DH, and metal coins of 0.5 DH, 5 DH, 1 DH, 2 DH, 5 DH and 10 DH.</p>
                    <p>The cashback credit card, checkbook or ATM is possible in all banks.
                    Credit cards can be used in major hotels, large retail outlets, restaurants and some shops.</p>
                    </TabPane>
                    <TabPane tab="Working time" key="6">
                    <p>Moroccan working official time in both public and private services is scheduled continuously from 8am to 16:30 pm.
                    The Same timing is retained for many private companies including banks.
                    </p>
                    </TabPane>
                    <TabPane tab="Places of worship in Tangier" key="7">
                    <p>There are mosques in major districts of the city.
                    </p>
                        <strong>Cathedrale</strong><br />
                        Sidi Bouabid, Tanger Maroc<br />
                        Tél.: 05 39 932 762<br /><br />

                        <strong>Shaar Raphael Synagogue</strong><br />
                        26 Bd Pasteur, Tanger Maroc<br /><br />
                        
                        <strong>Ben Atar Synagogue</strong><br />
                        1 rue de la liberté, Tanger Maroc<br />

                    </TabPane>
                    <TabPane tab="Emergencies" key="8">
                        <p><strong>Police</strong>: Tél. : 19</p>
                        <p><strong>Fire department </strong>: Tél. : 15</p>
                        <p><strong>Road safety </strong>: Tél. : 177</p>
                        <p><strong>Phone services</strong>: Tél. : 160</p>
                        <p><strong>Mohammed V hospital</strong>: Tél.: 05 39 93 42 42 / 05 39 93 24 44 / 05 39 93 10 73</p>
                        <p><strong>Home health care</strong>: Tél.: 05 39 33 33 00</p>
                        <p><strong>Quick rescue</strong>: Tél.: 05 39 95 40 40</p>
                        <p><strong>Moroccan Red crescent</strong>: Tél.: 05 39 35 20 20 </p>

                  </TabPane>

          </Tabs>
            </Container>
            <Spacer top='100px'/>
      
        </div>
    )
}


