import {SET_PLACES} from '../actions/places'

const initState = {
    places: {}
}
  
export default function placesReducer (state = initState, action) {
    switch (action.type) {
      case SET_PLACES:
            const allPlaces = action.payload;
            const deals = allPlaces.filter(p => p.isDeal);
            const nonDeals = allPlaces.filter(p => !p.isDeal);
            return {
              places: nonDeals,
              deals: deals
        }
      default:
        return state
    }
  }
  