import React, { Suspense, useState, useEffect } from 'react';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next'
import LayoutData from './components/LayoutData'
import ErrorFallBack from './components/ErrorFallback'
import {ErrorBoundary} from 'react-error-boundary'
import Home from './containers/HomeContainer'
import Adherent from './containers/AdherentContainer'
import AdherentDetails from './containers/AdherentContainer/AdherentNewsDetails'
import AdherentTools from './containers/AdherentContainer/Tools'
import PlaceDetail from './containers/PlaceDetailContainer'

import Favorites from './containers/FavoritesContainer'
import Quotes from './containers/QuotesContainer'
import QuotesStartConvo from './containers/QuotesContainer/StartAConversation'
import ContactUs from './containers/Contact'
import Privacy from './containers/PrivacyPolicy'
import PracticalInfo from './containers/PracticalInfo'
import isMobile from 'ismobilejs'


import ScrollToTop from './components/ScrollToTop'
import Loading from './components/Loading'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css'

const Experience = React.lazy(() => import('./containers/ExperienceContainer'))
const Collection = React.lazy(() => import('./containers/CollectionContainer'))
const Destination = React.lazy(() => import('./containers/DestinationsContainer'))
const TripPlanner = React.lazy(() => import('./containers/TourBuilder/builder'))
const Deals = React.lazy(() => import('./containers/DealsContainer'))
//const Connect2021 = React.lazy(() => import('./containers/Connect2021Container'))
const Mice = React.lazy(() => import('./containers/MiceContainer'))
const MiceQuote = React.lazy(() => import('./containers/MiceContainer/AskForQuotePage'))
const SearchEngine = React.lazy(() => import('./containers/SearchEngine'))
const VisaInfo = React.lazy(() => import('./containers/VisaInfo'))
const TripIdeas = React.lazy(() => import('./containers/TripIdeas'))
const PressContainer = React.lazy(() => import('./containers/PressContainer'))
const PressDetails = React.lazy(() => import('./containers/PressContainer/PressNewsDetails'))
const DealsDetail = React.lazy(() => import('./containers/DealsContainer/DetailsDetails'))
const GuideMap = React.lazy(() => import('./containers/GuideMap'))
const GettingHere = React.lazy(() => import('./containers/GettingHere'))



function App() {
  const { t } = useTranslation()

  const [appLoading, setAppLoading] = useState(true)
  
  useEffect(() => {
    setTimeout(function () {
      setAppLoading(false)
    }, 2000)
  },[])
  return (
    
    <Router>
      <LayoutData>
      <ErrorBoundary
      FallbackComponent={ErrorFallBack}
    >
        <ScrollToTop>
          {
            appLoading ? <Loading /> : <Switch>
            {
              /**
               *   <Route
            exact
            path="/:locale/connect2021/deal/:id"
            render={props => <Suspense fallback={<Loading/>}>
            <DealsDetail route={props} isDeal euro/>
                        </Suspense>}
              />
               */
            }
          
            <Route
            exact
            path="/:locale/press/:id"
            render={props =>   <Suspense fallback={<Loading/>}>
            <PressDetails route={props} />
          </Suspense>}
            />
              
            <Route
            exact
            path="/:locale/press"
            render={props => (
              <Suspense fallback={<Loading/>}>
                <PressContainer route={props} />
              </Suspense>
            )}
              />

              

            <Route
            exact
            path="/:locale/privacy-policy"
                render={props => <Privacy route={props} />} />
                <Route
                exact
                path="/:locale/guide-maps"
                render={props =>
                  <Suspense fallback={<Loading/>}>
                  <GuideMap route={props} />
              </Suspense>
                 } />
                <Route
                exact
                path="/:locale/practical-infos"
                render={props => <PracticalInfo route={props} />} />
            <Route
                exact
                path="/:locale/getting-to-tangier"
                render={props =>
                  <Suspense fallback={<Loading/>}>
                  <GettingHere route={props} />
              </Suspense>
                  } />
              
                <Route
                exact
                path="/:locale/contact-crt-tta"
                render={props => <ContactUs route={props} />} />
         

                <Route
              exact
              path="/:locale/visa"
              render={props => <Suspense fallback={<Loading/>}>
                              <VisaInfo route={props} />
                          </Suspense>}
              />

              <Route
              exact
              path="/:locale/trip-ideas"
              render={props => <Suspense fallback={<Loading/>}>
                              <TripIdeas route={props} />
                          </Suspense>}
              />

            <Route
              exact
              path="/:locale/deals"
              render={props => <Suspense fallback={<Loading/>}>
                              <Deals route={props} />
                          </Suspense>}
                />
                
                {
                  /**
                   *  <Route
                exact
                path="/:locale/connect2021"
                render={props => <Suspense fallback={<Loading/>}>
                                <Connect2021 route={props} />
                            </Suspense>}
                />
                   */
                }
               
               

              <Route
              exact
              path="/:locale/search/:query?"
              render={props => <Suspense fallback={<Loading/>}>
                              <SearchEngine route={props} />
                          </Suspense>}
              />


              
              <Route
              exact
              path="/:locale/mice"
              render={props => <Suspense fallback={<Loading/>}>
                              <Mice route={props} />
                          </Suspense>}
              />
  
              <Route
              exact
              path="/:locale/mice/quote"
              render={props => <Suspense fallback={<Loading/>}>
                              <MiceQuote route={props} />
                          </Suspense>}
            />
  
            
              <Route
              exact
              path="/:locale/mice/:id"
              render={props =>  <PlaceDetail route={props} isMice/>}
              />
              
             
            <Route
              exact
              path="/:locale/trip-planner/:tour?"
              render={props => (
                <Suspense fallback={<Loading/>}>
                  <TripPlanner route={props} />
                </Suspense>
              )}
            />
  
            <Route
              exact
              path="/:locale/favorites"
              render={props => <Favorites route={props} />}
              />
              <Route
                exact
                path="/:locale/quotes/new/:to"
                render={props => <QuotesStartConvo route={props} />}
              />
              
              <Route
                exact
                path="/:locale/quotes/:id?/:email?"
                render={props => <Quotes route={props} />}
              />
  
            <Route
              exact
              path="/:locale/destination/:city"
              render={props => (
                <Suspense fallback={<Loading/>}>
                  <Destination route={props} />
                </Suspense>
              )}
              />
              
             
  
            <Route
              exact
              path="/:locale/explore/:slug?"
              render={props => (
                <Suspense fallback={<Loading/>}>
                  <Collection route={props} />
                </Suspense>
              )}
            />
  
            <Route
              exact
              path="/:locale/experience/:id"
              render={props => (
                <Suspense fallback={<Loading/>}>
                  <Experience route={props} />
                </Suspense>
              )}
              />
              
              <Route
              exact
              path="/:locale/deal/:id"
                render={props =>
                  <Suspense fallback={<Loading/>}>
                  <DealsDetail route={props} isDeal />
                </Suspense>
                 }
            />
  
           
  
              <Route
              exact
              path="/:locale/adherent/:cat/:id"
              render={props =>  <AdherentDetails route={props}/>}
              />
  
              
              <Route
              exact
              path="/:locale/adherent"
              render={props => <Adherent route={props} />
                         }
              />

              <Route
              exact
              path="/:locale/adherent/tools"
              render={props => <AdherentTools route={props} />
                         }
              />
  
              <Route
              exact
              path="/:locale/:cat/:slug/:id"
              render={props => <PlaceDetail route={props} />}
              />
              
  
            <Route path="/:locale?">
            <Home />
              </Route>
          </Switch>
          }
        
        </ScrollToTop>
        <CookieConsent
  location={isMobile().phone ? "top": "bottom"}
          buttonText={t('iUnderstand')}
          containerClasses={'coockie-consent'}
  cookieName="visittanger"
  style={{ background: "rgba(8,71,117,.6)" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
>{t('coockieConscentText')}

        </CookieConsent>
        </ErrorBoundary>
        </LayoutData>
      </Router>
      
  )
}

export default App;

