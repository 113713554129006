import { SET_LOCAL } from '../actions/local'

const initState = {
    local: null
}
  
export default function localReducer (state = initState, action) {
    switch (action.type) {
      case SET_LOCAL:
            return {
                local: action.payload
        }
      default:
        return state
    }
  }
  
