import React, {useState} from 'react'
import Container from '../../components/Container'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import Spacer from '../../components/Spacer'
import BigTitle from '../../components/BigTitle'
import { Redirect } from "react-router-dom";
import { API_CONTACT, JSON_HEADERS } from "../../constants";

import { Row, Col, Tag, Input, Button } from 'antd'
import {validateEmail} from '../../helpers'
import Lottie from 'react-lottie'
import * as animationData from '../../animations/6230-loading-37-brochure.json'
const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  

const { CheckableTag } = Tag
const cats = ['Catalogue', 'Carte Touristique', 'Plan de développement', 'Ligne de bus']
const formats = ['Papier', 'Électronique']

export default function RequestMaterial({ cities, local }) {
  const [sent, setSent] = useState(false)
  const { t } = useTranslation()
    const [form, setForm] = useState({ email: null, name: null, address: null, city: null, company: null })
  const [filter, setFiltres] = useState({ cities: [], formats: [], cats: [] })
  
  function submit(form) {
    if (!validateEmail(form.email)) {
      message.error("Email address is invalid");
      return;
    }

    if (!form.name) {
      message.error("Name is required");
      return;
    }

    if (!form.address) {
      message.error("Address is required");
      return;
    }

    fetch(API_CONTACT, {
      method: "POST",
      body: JSON.stringify({name:form.name, from:form.email, message: form.address+'///'+cities.join(', ')+'////'+cats.join(', ')}),
      headers: { ...JSON_HEADERS },
    })
      .then(() => {
        //send Email here
        message.success("Thank you for your message");
        setSent(true);
      })
      .catch((e) => message.error("An error occured"));
  }
    
    const handleCities = (value, checked) => {
        const newCats = Array.isArray(filter.cities)
          ? filter.cities.indexOf(value) > -1
            ? filter.cities.filter(f => f !== value)
            : [...filter.cities, value]
          : [value];
          setFiltres({ ...filter, cities: newCats });
    };
    
    const handleCats = (value, checked) => {
        const newCats = Array.isArray(filter.cats)
          ? filter.cats.indexOf(value) > -1
            ? filter.cats.filter(f => f !== value)
            : [...filter.cats, value]
          : [value];
          setFiltres({ ...filter, cats: newCats });
      };
    
      const handleFormats = (value, checked) => {
        const newCats = Array.isArray(filter.formats)
          ? filter.formats.indexOf(value) > -1
            ? filter.formats.filter(f => f !== value)
            : [...filter.formats, value]
          : [value];
          setFiltres({ ...filter, formats: newCats });
      };
    
    
    return (
        
        <div className='mice-request-material'>
            <Container>
             
        <BigTitle title={t('requestMaterial')} />
                <Spacer bottom={'50px'} />
                <Row gutter={5} className={'request-material-filters'}>
                    <Col md={12} style={{padding:'20px', backgroundColor:'#f7f7f7', borderRadius:'7px'}}>
                    
                    <Col md={24} >
                <p>{t('selectCity')}</p>
                    {cities && cities.map(city => (
                      <CheckableTag
                        key={city.slug}
                        checked={filter.cities.indexOf(city.slug) > -1}
                        onChange={checked => handleCities(city.slug, checked)}
                      >
                        {city.title[local]}
                      </CheckableTag>
                    ))}
                    </Col>

                        <Col md={24}>
                        <Spacer bottom={'20px'} />
                <p>{t('selectCat')}</p>
                    {cats && cats.map(cat => (
                      <CheckableTag
                        key={cat}
                        checked={filter.cats.indexOf(cat) > -1}
                        onChange={checked => handleCats(cat, checked)}
                      >
                        {cat}
                      </CheckableTag>
                    ))}
                    </Col>

                        <Col md={24}>
                        <Spacer bottom={'20px'} />
                <p>{t('selectFormat')}</p>
                    {formats && formats.map(format => (
                      <CheckableTag
                        key={format}
                        checked={filter.formats.indexOf(format) > -1}
                        onChange={checked => handleFormats(format, checked)}
                      >
                        {format}
                      </CheckableTag>
                    ))}
                    </Col>
                    </Col>

                    <Col md={12}>
                        {
                            (filter.cities && filter.cities.length > 0) ?
                                (
                                    <div>
                                    <Col md={24}>
                                    <Input
                          placeholder={t('yourEmail')}
                                      type="email"
                                      onChange={e =>
                                        setForm({
                                          ...form,
                                          email: e.target.value
                                        })
                                      }
                                    />
                                        </Col>
                                        <Col md={24}>
                                    <Input
                          placeholder={t('yourName')}
                                    
                                      onChange={e =>
                                        setForm({
                                          ...form,
                                          nom: e.target.value
                                        })
                                      }
                                    />
                                        </Col>
                                        <Col md={24}>
                                    <Input
                          placeholder={t('yourAddress')}
                                      type="address"
                                      onChange={e =>
                                        setForm({
                                          ...form,
                                          address: e.target.value
                                        })
                                      }
                                    />
                                        </Col>
                                       
                                    <Col md={24}>
                                    <Spacer bottom={'20px'} />
                                   <Button type='primary' onClick={()=>submit(form)}>{t('sendRequest')}</Button>
                                  </Col></div>
                               ) :     <Lottie options={defaultOptions} height={'100%'} width={300} />
                        }
                  
                   
                    </Col>
                    
                    
               
                </Row>
                <Row gutter={8}>
              
                </Row>
                    
                <div className='request-material-bg'>
                
               
                </div>
        <Spacer top={'50px'} />
        </Container>
        {sent && <Redirect to="/" />}
        </div>
    )    
}
