import React from 'react'
import { Tabs } from 'antd';
import Container from '../../components/Container'
import BreadCramb from './Breadcramb'
import Spacer from '../../components/Spacer'
import Visa from '../VisaInfo'
import Seo from './PraticalInfoSeo'
const { TabPane } = Tabs;



export default function GettingHere() {
    return (
        <div className='getting-here-container'>
        <Seo />
            <Spacer top='20px' />
            
            <Container>
                <BreadCramb local='fr' />
                <Spacer top='50px' />
            <Tabs defaultActiveKey="1" >
            <TabPane tab="Hora Local" key="1">
            GMT. En verano, del ultimo domingo de marzo a finales de octubre, se pasa al horario de verano con GMT+1. En ramadán, que últimamente coincide con el verano, se regresa al GMT.
            </TabPane>
            <TabPane tab="Clima " key="2">
              <p >La región esta bajo la influencia del clima mediterráneo.
              Las temperaturas son suaves en invierno y moderadas en verano, tanto en la costa como en las alturas.
              En enero, las mínimas, raramente descienden por debajo de los cero grados en las alturas y las máximas se quedan entre los 14 y 18 grados.
              En verano, el clima se vuelve más caliente y las temperaturas máximas en julio varían entre 16 y 26 grados. Algunos años se alcanzan máximas de 32 a 38 grados pero son excepcionalmente raras.
              </p>
                        <p>Previsiones meteorológicas:<br/>Tel: 05 22 90 24 22</p>
            </TabPane>
            <TabPane tab="Visados" key="3">
              <Visa />
                    </TabPane>
                    <TabPane tab="Idiomas " key="4">
                 <p>Los idiomas nacionales son el Árabe y amazigh.
                 En Tánger, Tetuán y Alhucemas se hablan varios idiomas, incluyendo español, francés e Inglés.
                 
                 
                 </p>
                    </TabPane>
                    <TabPane tab="Moneda " key="5">
                   <p>La moneda de Marruecos es el Dirham (MAD), dividido en 100 centavos. Existen billetes de 20, 50,100 y 200 DH, y monedas de 0,5 DH, 5 DH, DH 1, 2 HR, 5 DH y 10 DH.</p>
                    <p>Se puede retirar dinero en efectivo con tarjeta de crédito o talonario de cheques en todas las sucursales bancarias. Las tarjetas de crédito se pueden utilizar en los principales hoteles, grandes superficies comerciales, restaurantes y algunas tiendas.</p>
                    </TabPane>
                    <TabPane tab="Horarios de trabajo" key="6">
                    <p>El horario de trabajo en las administraciones y servicios públicos es continuo de 8 a 16:30 horas.
                    Algunos establecimientos privados también siguen el mismo horario como los bancos.
                    </p>
                    </TabPane>
                    <TabPane tab="Lugares de culto en Tánger" key="7">
                    <p>Existen mezquitas en los principales barrios de la ciudad.
                    </p>
                        <strong>Catedral</strong><br />
                        Sidi Bouabid, Tanger Maroc<br />
                        Tél.: 05 39 932 762<br /><br />

                        <strong>Sinagoga Shaar Raphael</strong><br />
                        26 Bd Pasteur, Tanger Maroc<br /><br />
                        
                        <strong>Sinagoga Ben Atar</strong><br />
                        1 rue de la liberté, Tanger Maroc<br />

                    </TabPane>
                    <TabPane tab="Urgencias" key="8">
                        <p><strong>Policía </strong>: Tél. : 19</p>
                        <p><strong>Bomberos  </strong>: Tél. : 15</p>
                        <p><strong>Seguridad en carretera</strong>: Tél. : 177</p>
                        <p><strong>Información telefónica</strong>: Tél. : 160</p>
                        <p><strong>Hospital Mohammed V</strong>: Tél.: 05 39 93 42 42 / 05 39 93 24 44 / 05 39 93 10 73</p>
                        <p><strong>Médico a domicilio</strong>: Tél.: 05 39 33 33 00</p>
                        <p><strong>Primeros auxilios</strong>: Tél.: 05 39 95 40 40</p>
                        <p><strong>Creciente rojo marroquí (Cruz roja marroquí):</strong>: Tél.: 05 39 35 20 20 </p>

                  </TabPane>

          </Tabs>
            </Container>
            <Spacer top='100px'/>
      
        </div>
    )
}


