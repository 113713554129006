import { SET_USER, SET_USER_ID, SET_USER_SUBSCRIBED } from '../actions/user'

const initState = {
  userId: null,
  user: {},
  newsletter: false,
  subscribedToNewsletter: false
}
  
export default function userReducer (state = initState, action) {
    switch (action.type) {
      case SET_USER:
        return {
              ...state,
              user:action.payload
        }
        case SET_USER_ID:
          return {
                ...state,
                userId: action.payload
        }
      case SET_USER_SUBSCRIBED:
        return {
          ...state,
          newsletter: true,
          subscribedToNewsletter: true
        }
      default:
        return state
    }
  }
  
