import React, {useState} from 'react'
import { connect } from 'react-redux'
import useScroll from '../hooks/useScroll'
import { useTranslation } from 'react-i18next';
import { Badge } from 'antd'
import MobileMenu from './MobileMenu'
import isMobile from 'ismobilejs';
import SubMenuExplore from './SubMenuExplore.js'
import SubMenuMobile from './SubMenuMobile.js'
import SubMenuPlan from './SubMenuPlan.js'
import { Link } from 'react-router-dom'
import logo from '../images/logo-crt-fr-inline.png'
import heart from '../images/heart.png'
import search from '../images/search.png'
import exploreIcon from '../images/explore-icon.png'
import planIcon from '../images/plan-icon.png'
import dealsIcon from '../images/deals-icon.png'
import '../css/MainHeader.css';

const RenderMenuItem = ({ menu, setShowMenu }) => {
  

  if (menu.link) {
    return (
      <Link to={menu.link} className="Main-menu-item">
        <div className="icon-holder">
          <img src={menu.icon} className="menu-icon-image" alt={menu.title} />
        </div>
        <span className="Main-menu-text">{menu.title}</span>
      </Link>
    );
  } else if (menu.url) {
    return (<a href={menu.url} className="Main-menu-item" target='blank' rel='no-follow'>
        <div className="icon-holder">
          <img src={menu.icon} className="menu-icon-image" alt={menu.title} />
        </div>
        <span className="Main-menu-text">{menu.title}</span>
      </a>)
  } else {
    return (
      <div
                  className="Main-menu-item"
                  onClick={() => setShowMenu(menu.slug)}
                >
                  <div className="icon-holder">
                    <img
                      src={menu.icon}
                      className="menu-icon-image"
                      alt={menu.title}
                    />
                  </div>
                  <span className="Main-menu-text">{menu.title}</span>
                </div>
    )
  }
                
}

function MainHeader({ favorites, instagram, local, tags, cities }) {
  const { t } = useTranslation();
  const { scrollDirection, scrollY } = useScroll()
  const [showMenu, setShowMenu] = useState(false)
  
  const menuItems = [
    {
      title: t("explore"),
      icon: exploreIcon,
      slug: 'explore'
    },
    {
        title: t("plan"),
      icon: planIcon,
        slug: 'plan'
    },
    {
        title: t('deals'),
      icon: dealsIcon,
      link: '/'+local+'/deals',
       
    }
  ]

  return (
    <div>
      <header
        className={
          ((scrollDirection === "up" && scrollY > 1800) && !isMobile().phone)
            ? "App-header-classic App-header-classic-fixed"
            : isMobile().phone ? "App-header-classic App-header-classic-mobile" : "App-header-classic"
        }
      >
        <div className="App-wrapper">
          <div className="center-image">
            <Link to="/">
              <img src={logo} className="App-logo-white" alt="App-logo-white" />
            </Link>
          </div>


          <MobileMenu setShowMenu={setShowMenu}/>
         
         

          <div style={{ width: "100%", zIndex: 999999 }}>
            <div className="Main-menu-wrapper">
              {menuItems.map((m, k) => (
                <RenderMenuItem setShowMenu={setShowMenu} menu={m} key={'menu-100'+k}/>
              ))}
            </div>
          </div>

          <div className="menu-icons-right">
            <div className={local === 'ar' ? "left-30" : "right-30"}>
              <Link to={`/${local}/favorites`}>
                <Badge
                  count={favorites.length}
                  style={{
                    backgroundColor: "#fff",
                    color: "#999",
                    boxShadow: "0 0 0 1px #d9d9d9 inset"
                  }}
                >
                  <img src={heart} className="header-icons" alt="heart" />
                </Badge>
              </Link>
            </div>

           
            <div>
            <Link to={`/${local}/search`}>
                <img src={search} className="header-icons" alt="search" />
                </Link>
            </div>
              
          </div>
        </div>
      </header>

     
      {
        showMenu === 'explore' && <SubMenuExplore tags={tags} cities={cities} showMenu={showMenu} setShowMenu={setShowMenu} instagram={instagram} local={local}/>
      }

      {
        showMenu === 'plan' &&  <SubMenuPlan tags={tags} cities={cities} showMenu={showMenu} setShowMenu={setShowMenu} instagram={instagram} local={local}/>
      }

      {
        showMenu === 'Mobile' &&  <SubMenuMobile tags={tags} cities={cities} showMenu={showMenu} setShowMenu={setShowMenu} instagram={instagram} local={local}/>
      }
     
      
    </div>
  );
}


const mapStateToProps = state => {
  return {
    ...state.Favorites,
    ...state.Instagram,
    ...state.Local,
    ...state.Tags,
    ...state.Cities
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      
}
}

const appConnect = connect(mapStateToProps, mapDispatchToProps)(MainHeader)

export default appConnect