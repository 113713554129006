import React from 'react'
//import TourBuilder from '../../components/TourBuilder'
import BigTitle from '../../components/BigTitle'
import { Row } from 'antd'
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import * as animationData from '../../animations/14579-process.json'
import HomeCustomizeForm from '../../components/HomeCustomizeForm'
import Spacer from '../../components/Spacer'

import Container from '../../components/Container'

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};



export default function TourBuilderContainer({ title }) {
  const {  t  } = useTranslation();

    return (
      <div>
   
        <Container>
          <BigTitle title={title || t('tripPlanner')} />
          <Spacer bottom={'50px'} />
          </Container>
          <Row>
           
            <Container>
            
            <HomeCustomizeForm tripPlanner/>
            
              </Container>

            <div style={{top:'-15vh',  zIndex:-1, width:'100%', position:'absolute', right:0, left:0, overflow:'hidden'}}>
            <Lottie options={defaultOptions}
          height={700}
          width={'100vw'}/>
            
            </div>
          </Row>
       
         

        
          
        
        <Spacer bottom='77px' />
      </div>
    );



}

