import React from 'react'
import TripPlanner from '../TourBuilder'
import VisaInfo from '../VisaInfo'
import PhotoBankBanner from './PhotoBankBanner'
import AdherentSeo from './AdherentsSeo'

import Container from '../../components/Container'
import Spacer from '../../components/Spacer'
export default function AdherentTools() {
    return (
        <Container>
            <AdherentSeo title='Outils' />
            <Spacer top='50px' />
            <TripPlanner />
            <VisaInfo />
            <PhotoBankBanner />
        </Container>
    )
}