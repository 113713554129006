import React from 'react'
import { Icon } from 'antd'

export default function SocialIcons() {
    return (
        <div className='App-wrapper-no-margins' style={{marginRight:'10px', marginLeft:'10px', width:'100px', display: 'flex', justifyContent:'space-between'}}>
            <a href='https://www.facebook.com/VisitChamal' rel='no-follow' target='Blank'><Icon type="facebook" style={style}/></a>
            <a href='https://www.instagram.com/visitchamal/' rel='no-follow' target='Blank'><Icon type="instagram" style={style}/></a>
            <a href='https://www.youtube.com/channel/UCJhAQ6HCSj8cCGcgEbGG8vg/videos' rel='no-follow' target='Blank'><Icon type="youtube" style={style}/></a>
        </div>
    )
}

const style = {
    color:'white', fontSize:'19px', 
}