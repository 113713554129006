import React, { useState } from 'react'
import { Input, Button, message, Checkbox } from 'antd';
import { connect } from 'react-redux'
import Spacer from './Spacer'
import { useTranslation } from 'react-i18next';
import CheckAnim from './CheckAnim'
import { API_NEWSLETTER, JSON_HEADERS } from '../constants'
import {setSubscribedToNewsletter} from '../actions/user'
import { validateEmail } from '../helpers' 

function SubscribeNewsletter({ user, onAdded =()=>{}, setSubscribedToNewsletter }) {
    const { t } = useTranslation()
    const [subscribed, setSubscribed] = useState(false)
    const [checked, setChecked] = useState(false)
    const [email, setEmail] = useState()

    function submit(email, user) {
        if(!validateEmail(email)) return message.error('Email address is Invalid')
        fetch(API_NEWSLETTER, {
            method: "POST",
            body: JSON.stringify({email, user}),
            headers: { ...JSON_HEADERS },
          })
            .then(() => {
              //send Email here
              setSubscribed(true);
              setSubscribedToNewsletter()
            })
            .catch((e) => message.error("An error occured"));
    }
    
    

    

    return (
       
        <div style={{ margin: 0, paddingTop: '20px' }} >
            
            {
                !subscribed && <div><Input placeholder={t("yourEmail")} style={{ boxShadow: 'none', textTransform: 'capitalize' }} onChange={e=>setEmail(e.target.value)}/>
                    <Spacer bottom='30px' />
                    <Checkbox
                    checked={checked}
                    disabled={false}
                    onChange={()=>{setChecked(!checked)}}
                  >
                  J’accepte les conditions de confidentialité et d’utilisation du site web. J’autorise le CRT Tanger-Tétouan-Al Hoceima à me communiquer les meilleures offres et les nouvelles de la région par e-mail.
                  </Checkbox><br></br><br></br>
                    <Button disabled={!checked} type='primary' onClick={() => {
                        submit(email, user)
                        onAdded()
                    }}>{t("keepMeInformed")}</Button></div>
            }
            
            {
                subscribed  && <CheckAnim />
            }
            
       
        </div>
    )
}

const mapStateToProps = state => {
    return {
        ...state.User
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
     setSubscribedToNewsletter: ()=>dispatch(setSubscribedToNewsletter())
  }
  }
  
  const appConnect = connect(mapStateToProps, mapDispatchToProps)(SubscribeNewsletter)
  
  export default appConnect;