import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import Container from './Container'
import { useTranslation } from 'react-i18next'
import MegaMenuContainer from './MegaMenuContainer'
import {EXCLUDED_TAGS} from '../constants'


const smartPlanner = [
    {
        title: 'Smart Planner',
        link: 'trip-planner'
    },
    {
        title: 'howToGetThere',
        link: 'getting-to-tangier'
    },
    {
        title: 'guideMap',
        link: 'guide-maps'
    },
    {
        title: 'hotels',
        link: 'explore/hotel'
    },
    {
        title: 'travelAgencies',
        link: 'explore/agence-de-voyages'
    }
]


export default function SubMenuExplore({ showMenu, setShowMenu, local, tags, cities }) {
    const { t } = useTranslation()
    const [filtredTags, setFiltredTags] = useState(tags)
    useEffect(() => {
      if (tags && Array.isArray(tags)) {
        const filtertags = tags.filter(t => EXCLUDED_TAGS.indexOf(t.slug) === -1)
        setFiltredTags(filtertags)
      }
    },[tags])
    return (
      <MegaMenuContainer showMenu={showMenu} setShowMenu={setShowMenu}>
        <Container>
          <Row>
            <Col md={8}>
                        <div className="mega-menu-modal-left-content">
                            <div className="mega-menu-modal-sb-title">{t('explore')}</div>
                            {
                                filtredTags.map((item, k) => (
                                    <Link key={'explore-items'+k} to={'/'+local+'/explore/'+item.slug} onClick={()=>setShowMenu(false)}>
                                    <div >
                                        <h1>{item.name[local]}</h1>
                                    </div>
                                    </Link>
                                   
                                )
                                    
                                )
                            }
                
              </div>
                    </Col>
                    
                    <Col md={8}>
              <div className="mega-menu-modal-left-content">
                <div className="mega-menu-modal-sb-title">{t('destination')}</div>

                {
                  cities.map((item, k) => (
                      <Link key={'destination-items'+k} to={'/'+local+'/destination/'+item.slug} onClick={()=>setShowMenu(false)}>
                      <div >
                          <h1>{item.title[local]}</h1>
                      </div>
                      </Link>
                     
                  )
                      
                  )
                }
                
                
              </div>
                    </Col>
                    <Col md={8}>
                    <div className="mega-menu-modal-left-content">
                            <div className="mega-menu-modal-sb-title">{t('plan')}</div>
                    {
                        smartPlanner.map((item, k) => (
                            <Link key={'explore-items'+k} to={'/'+local+'/'+item.link} onClick={()=>setShowMenu(false)}>
                            <div >
                                <h1>{t(item.title)}</h1>
                            </div>
                            </Link>
                           
                        )
                            
                        )
                            }

                            <a href='https://visittanger.app' onClick={()=>setShowMenu(false)} target='blank'>
                            <div >
                                <h1>{t('mobileApps')}</h1>
                            </div>
                            </a>
                            </div>
        
                    </Col>

                    <Col md={8}>
                    <div className="mega-menu-modal-left-content">
                            <div className="mega-menu-modal-sb-title">_____</div>
                            <Link  to={'/'+local+'/contact-crt-tta'} onClick={()=>setShowMenu(false)}>
                            <div >
                                    <h1>{t('contact')}</h1>
                            </div>
                            </Link>
                            <Link  to={'/'+local+'/favorites'} onClick={()=>setShowMenu(false)}>
                            <div >
                                    <h1>{t('favorites')}</h1>
                            </div>
                            </Link>

                    <Link  to={'/'+local+''} onClick={()=>setShowMenu(false)}>
                    <div >
                                    <h1>{t('visitors')}</h1>
                    </div>
                    </Link>
                       
                            <Link  to={'/'+local+'/mice'} onClick={()=>setShowMenu(false)}>
                            <div >
                                    <h1>{t('proMice')}</h1>
                            </div>
                            </Link>

                            <Link  to={'/'+local+'/adherent'} onClick={()=>setShowMenu(false)}>
                            <div >
                                    <h1>{t('adherent')}</h1>
                            </div>
                            </Link>


                           
                       <div style={{paddingBottom:'150px'}} />
                            
                       
                            
                            </div>
        
                    </Col>
          </Row>
        </Container>
      </MegaMenuContainer>
    );
}