import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, Statistic, Empty, Breadcrumb } from 'antd'
import { useTranslation } from 'react-i18next'
import Container from '../../components/Container'
import Spacer from '../../components/Spacer'
import FavoriteSeo from './FavoriteSeo'
import ImageCard from '../../components/ImageCard'
import {local} from '../../translations'

//import Layout from '../../components/Layout'

function Favorites({ favorites, places }) {
  const { t } = useTranslation()
    const [selectedFav, setSelectedFavs] = useState([])
    const [visitDuration, setVisitDuration] = useState(0)
    const [cost, setCost] = useState(0)

    useEffect(() => {
        
        if (favorites && places && favorites[0] && places[0]) {
            setSelectedFavs(places.filter(place => favorites.indexOf(place._id) > -1))
        }
        
    }, [favorites, places])

    useEffect(() => {
        if (selectedFav && selectedFav[0]) {
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            const totalCost = selectedFav.map(f => f.misc.cost).reduce(reducer)   
            const totalTime = selectedFav.map(f=>f.misc.minVisitDuration).reduce(reducer)
            setCost(totalCost)
            setVisitDuration(totalTime)
        }
       
    },[selectedFav, setCost, setVisitDuration])
    
    return (
      <div>
        <FavoriteSeo />
       
        <Container>
        <Spacer top={"20px"} />
        <Breadcrumb>
            <Breadcrumb.Item><Link to={`/${local}`}>{t('home')}</Link></Breadcrumb.Item>
        <Breadcrumb.Item>
              <Link to={`/${local}/favorites`}>{t('favorites')}</Link>
        </Breadcrumb.Item>
       
       
          </Breadcrumb>
          <Spacer top={"5vh"} />
          
          {
            (!selectedFav || !selectedFav[0]) && <Empty description={false} />
          }

          {selectedFav && selectedFav[0] && (
                    <Row gutter={16}>
                        {
                            cost>0 && <Col span={6}>
                            <Statistic
                              title="Approx Cost"
                              value={cost + " Dhs"}
                              suffix=" MAD"
                              valueStyle={{ color: "var(--vt-blue)" }}
                            />
                          </Col>
                        }
              
                        
              <Col span={6}>
                <Statistic
                  title="Expected visit time"
                  value={" +" + Math.floor(visitDuration / 60)}
                  suffix=" h"
                  valueStyle={{ color: "var(--vt-blue)" }}
                />
              </Col>
            </Row>
          )}

          <Spacer bottom={"3vh"} />
          <Row style={{ minHeight: "60vh" }}  gutter={16}>
            {selectedFav.map((p, k) => {
              return (
                <Col
                md={8} sm={12} xs={24}
                  style={{ marginBottom: "50px" }}
                  key={k + Math.random()}
                >
                  <ImageCard
                    height={"20vh"}
                    _id={p._id}
                    url={p.media[0].url}
                    title={p.title[local]}
                    address={p.contact.address.street_address}
                  />
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    );
}

const mapStateToProps = state => {
    return {
      ...state.Places,
      ...state.Favorites
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
     
    }
  }
  
  const appConnect = connect(mapStateToProps, mapDispatchToProps)(Favorites)
  
  export default appConnect
  
  