import React from 'react'
import {Col, Row, Button} from 'antd'
import BigTitle from "../../components/BigTitle"
import Spacer from "../../components/Spacer"
import Container from "../../components/Container"

import Lottie from 'react-lottie';
import * as animationData from '../../animations/camera.json'

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};


export default function PhotoBank() {
    return (
        <Container>
        <div className='adherent-photo-bank-banner'>
            <Row>
               
                    <Col md={14} style={{padding:'10%'}}>
                        <BigTitle title='Banque de photos' subtitle='Libre de droits, hautes définitions' />
                        <Spacer bottom='20px' />
                        <a href="https://drive.google.com/drive/folders/1SQjQsrCQCwN4W84RVhNKGL_rb144SElg" target='blank'><Button type='primary'>Parcourir</Button></a>
                 </Col>
                <Col md={8}> <Lottie options={defaultOptions} width={"100%"} /></Col>
            
            </Row>
    
            </div>
            <Spacer bottom='50px' />
            </Container>
    )
}