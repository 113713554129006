import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../animations/check-animation.json'

const defaultOptions = {
  loop: false,
  autoplay: true, 
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};



export default function TourBuilderContainer() {
    return (
      <div>
        <Lottie options={defaultOptions} height={300} width={300} />
      </div>
    );



}

