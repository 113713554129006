import React from 'react'
import { Icon } from 'antd'
import isMobile from 'ismobilejs';


export default function MobileMenu({setShowMenu, color}) {
    return <div>
        {isMobile().phone &&
        (<div className='mobile-menu' onClick={()=>{setShowMenu('Mobile')}}>
            <Icon type="menu"  style={{color:color || 'black', fontSize:'30px'}}/>
        </div>
        )}
        </div>
      
}