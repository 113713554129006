import React from 'react'
import Container from './Container'
import Spacer from './Spacer'
import { Link } from 'react-router-dom'
import SubsribeNewsLetter from './SubscribeNewsLetter'
import { useTranslation } from 'react-i18next';
import '../css/Footer.css'
import nlBgTop from '../images/newsletter-box-top.gif'
import { LOGO_CRT, LOGO_REGION } from '../constants'
import logoOnmt from '../images/onmt.png'
import { cdnImage } from '../helpers'

export default function Footer({local}) {
    const {  t  } = useTranslation();

    return (
        <div className='site-footer'>
           
        <div style={{background:'url('+nlBgTop+') repeat-x 0 0', height:'5px'}}></div>
           <div className='site-footer-newsletter-wrapper' >
            <Spacer top='40px'/>
                <div className='Footer-sub-wrapper' >
                    
                    <div style={{ width: '80', margin: 0 }}>
                        <h1>{t("keepInTouch")}</h1>
                        <p>{t("keepInTouchSub")}</p>
                        <Spacer top='40px' />
                        <SubsribeNewsLetter />
                        <Spacer bottom='80px' />
                    </div>
                
                </div>

                <Spacer top='40px' />
                
                </div>
            <Container>
                <div className='Footer-wrapper'>
                    
                    {
                        /*
                        <div style={{ with: '25%' }}>
                        <h4 className='Footer-heading'>CRT TTA</h4>
                     
                        <ul className='Footer-list'>
                        <li>Mission</li>
                        <li>Carrières</li>
                        <li>Nouvelles</li>
                        <li>Plan d'action</li>
                        <li>Partenaires</li>
                        <li>Statistiques</li>
                    
                    </ul>
                    </div>
                    <div style={{ with: '25%' }}>
                        <h4 className='Footer-heading'>Explore</h4>
                            <ul className='Footer-list'>
                            <li>Explore Tangier</li>
                            <li>Explore Tetouan</li>
                                <li>Explore Al Hoceima</li>
                                <li>Gastronomy</li>
                            <li>Events</li>
                            <li>Trip Planner</li>
                            
                        
                        </ul>
                        </div>
                        
                    
                        
                        <div style={{ with: '25%' }}>
                        <h4 className='Footer-heading'>Plan</h4>
                            <ul className='Footer-list'>
                            <li>Tangier</li>
                            <li>Tetouan</li>
                            <li>Al Hoceima</li>
                            <li>Events</li>
                            <li>Trip Planner</li>
                        
                        </ul>
                        </div>
                        
                        <div style={{ with: '25%' }}>
                        <h4 className='Footer-heading'>Professionels</h4>
                            <ul className='Footer-list'>
                            <li>Adhérents</li>
                            <li>Mice</li>
                            <li>API</li>
                            <li>Statistiques</li>
                            <li>Média</li>
                        
                        </ul>
                    </div>


                  
              
                        
                        
                        */
                    }
                    </div>
                    
             
                <div className='logoWrapper'>
                    
                   
                    <img src={cdnImage(LOGO_REGION)} className='logo' alt='region-tta' />
                     <img src={cdnImage(LOGO_CRT)} className='logo' alt='crt' />
                    <img src={cdnImage(logoOnmt)} className='logo' alt='onmt' />
                    
                    </div>
                <div>
                   
                    <p style={{ margin: 0, textAlign:"center"}}>
                       
                        
                © 2021 Conseil Régional du tourisme Tanger Tétouan Al Hoceima.
                
                                
                </p>
                    
                <p style={{ margin: 0, textAlign:"center"}}>
                       
                        
                        <Link to={'/' + local + '/privacy-policy'}>{t('privacyPolicy')}</Link> 
                
                                
                    </p>
                    
                    <p style={{ marginTop: '10px', textAlign:"center", marginBottom:'100px'}}>
                    <a href="https://ctd.ma" target='blank' style={{color:'var(--vt-text-color-grey)', fontSize:'12px'}}>Made from Tangier By Casbah Tourism Development</a>
                            
            </p>
                  </div>
                <Spacer bottom='40px'/>
           
            </Container>
            
        </div>
    )
}