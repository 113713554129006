import React from 'react'
import isMobile from 'ismobilejs'
import SubHeaderDesktop from './SubHeaderDesktop'
import SubHeaderMobile from './SubHeaderMobile'


function SubHeader({ local, setLocal, isMice, filters }) {
  return (<div>
    {
      isMobile().phone ? <SubHeaderMobile local={local} setLocal={setLocal} isMice={isMice} filters={filters}/>  : <SubHeaderDesktop local={local} setLocal={setLocal} isMice={isMice} filters={filters}/>
  }
  </div>)
  
}

export default SubHeader;
