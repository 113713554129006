import {SET_FILTERS} from '../actions/filters'

const initState = {
    filters: { 
        tags: [], //[]
        cities: [], //[]
        keyword: null,
        startingPoint:  null,
        budget: null,
        dates:{},
        stade:'planning'
    }
}
  
export default function placesReducer (state = initState, action) {
    switch (action.type) {
      case SET_FILTERS:
            return {
            filters: {...state.filters, ...action.payload}
        }
      default:
        return state
    }
  }
  