import { SET_CITIES } from '../actions/cities'

const initState = {
    cities: []
}
  
export default function citiesReducer (state = initState, action) {
    switch (action.type) {
      case SET_CITIES:
            return {
              cities: action.payload.sort((a,b)=>a.order-b.order)
        }
      default:
        return state
    }
  }
  
