import { CDN_ENDPOINT, SUPPORTED_LOCALS, API_STATS_LIVE, NO_IMAGE } from './constants'

export function truncateString(str, maxLength = 10) {
  if(!str) return
  if (str.length <= maxLength) {
    return str
  }
  return str.slice(0, maxLength) + '...'
}

export function feedBi(data) {
  fetch(API_STATS_LIVE ,{
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  }).then(function(response) {
    return response.json();
  }).catch(function(e) {
    console.log('__BI__SYNC__ERROR');
  });
}

export function validateEmail(email) {
  const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}


export function citySlugToName(slug, cities, local) {
  return getSafe(()=>cities.filter(c=>c.slug===slug)[0].title[local]) || slug
}
export function findCommonElements(arr1, arr2) { 
  try {
    return arr1.some(item => arr2.includes(item)) 
  } catch (e) {
    return false
  }
  
} 

export function getLang(){
  if (navigator.languages !== undefined && navigator.languages[0]) {
    return navigator.languages[0].split('-')[0]; 
  } else {
    return 'en'
  }
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export function supportedLocal() {
    const current = getLang()
  
    if (SUPPORTED_LOCALS.indexOf(current) > -1) {
      return current
    } 
    return 'en'
}

export function phoneNumberNormalizer(phone) {
  try {
    if (phone.toString().indexOf("0") === 0 || phone.toString().indexOf("212") === 0) {
      return phone
    } else {
      return "+212 "+phone
    }
  
  }catch (e) {
    return phone
  }

}


export function shuffleArray(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}


export function cdnImage(url, scale = false, width="w_600") {
  if (!url) return NO_IMAGE
  if (/*scale*/false) {
    return url.replace('https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads', "https://res.cloudinary.com/comepic/image/fetch/c_scale,fl_progressive,q_auto,"+width+"/f_png/"+CDN_ENDPOINT)
  } else {
    return url.replace('https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads', CDN_ENDPOINT)
  }
 
}

export function getSafe(fn, defaultVal) {
  try {
      return fn();
  } catch (e) {
      return defaultVal;
  }
}

export function meterDisplay(m) {
  if (m < 1000) {
    return m.toFixed(0) +' m'
  } 

  if (m >= 1000) {
    return (m/1000).toFixed(2) +' km'
  }
 
}

//https://stackoverflow.com/questions/4959975/generate-random-number-between-two-numbers-in-javascript
export function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
}


//https://stackoverflow.com/questions/50452059/sort-data-by-closest-geolocation-with-filtering
export function getDistance(lat1, lon1, lat2, lon2) {
  var R = 6371; // km
  var dLat = toRad(lat2-lat1);
  var dLon = toRad(lon2-lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c;
  return d;
}

// Converts numeric degrees to radians
function toRad(Value){
    return Value * Math.PI / 180;
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function selectBiggestImages(media) {
  return media.sort((a, b)=>a.size-b.size)[0]
}

// calculte nbr of days btw two dates
export function diffInDays(dateOne, dateTwo) {
  const timeOne = new Date(dateOne).getTime()
  const timeTwo = new Date(dateTwo).getTime()
  const diff =  (timeTwo - timeOne)/(1000*3600*24)
  if (diff > 0) {
    return diff
  }
  return 1
}


//calculate bearing
/***Begin of bearing calculation */
// Converts from degrees to radians.
function toRadians(degrees) {
  return degrees * Math.PI / 180;
};
 
// Converts from radians to degrees.
function toDegrees(radians) {
  return radians * 180 / Math.PI;
}


export function CalculateBearing(startLat, startLng, destLat, destLng) {
    
  if (!startLat || !startLng || !destLat || !destLng) {
      return 0
  }
   startLat = toRadians(startLat);
   startLng = toRadians(startLng);
   destLat = toRadians(destLat);
   destLng = toRadians(destLng);

  const y = Math.sin(destLng - startLng) * Math.cos(destLat);
  const x = Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
        let  brng = Math.atan2(y, x);
         brng = toDegrees(brng);
  return (brng + 360) % 360;
}

/**
 * https://stackoverflow.com/questions/40774697/how-to-group-an-array-of-objects-by-key
 * const cars = [{ make: 'audi', model: 'r8', year: '2012' }, { make: 'audi', model: 'rs5', year: '2013' }, { make: 'ford', model: 'mustang', year: '2012' }, { make: 'ford', model: 'fusion', year: '2015' }, { make: 'kia', model: 'optima', year: '2012' }];
 * const result = groupBy(cars, (c) => c.make);
 * 
 */

export function groupBy(xs, f) {
  // eslint-disable-next-line
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k.toLowerCase()] || (r[k.toLowerCase()] = [])).push(v), r), {});
}


export const betterGroupBy  = key => array =>
array.reduce((objectsByKeyValue, obj) => {
  const value = obj[key];
  objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
  return objectsByKeyValue;
}, {});

/* eslint-disable */
export function slugify(text, local) {
  if(local === 'ar' && text) return text.replace(' ', '-')
  try {
    return text.toString().toLowerCase()
    .replace(new RegExp("[àáâãäå]", 'g'),"a") // handling diactritics
    .replace(new RegExp("æ", 'g'),"ae")
    .replace(new RegExp("ç", 'g'),"c")
    .replace(new RegExp("[èéêë]", 'g'),"e")
    .replace(new RegExp("[ìíîï]", 'g'),"i")
    .replace(new RegExp("ñ", 'g'),"n")                            
    .replace(new RegExp("[òóôõö]", 'g'),"o")
    .replace(new RegExp("œ", 'g'),"oe")
    .replace(new RegExp("[ùúûü]", 'g'),"u")
    .replace(new RegExp("[ýÿ]", 'g'),"y")
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
} catch (e) {
    return 'default';
  }
  
  
}