import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Divider } from "antd";
import ScrollContainer from "react-indiana-drag-scroll";
import Slide from "react-reveal/Slide";

import { cdnImage } from "../../helpers";
import Container from "../../components/Container";
import Spacer from "../../components/Spacer";

export default function AdherentsNews({ articles, local }) {
  const [news, setNews] = useState([]);
  useEffect(() => {
    if (articles && Array.isArray(articles)) {
      const filtredArticles = articles.filter(
        (a) => a.tags.indexOf("news") > -1
      );
      setNews(filtredArticles);
    }
  }, [articles]);
  return (
    <Slide top>
      <div className="adherent-container">
        <Spacer top="20px" />
        <Container>
          {news && news[0] && news[0].title && (
            <Row gutter={24}>
              <Spacer top="50px" />
              {
                <div>
                  {news[0].attachment ? (
                    <a href={news[0].attachment} target="blank">
                      <Col md={5} sm={24}>
                        <h1>{news[0].title}</h1>

                        <Divider />
                      </Col>
                    </a>
                  ) : (
                    <Link to={`/${local}/adherent/news/${news[0]._id}`}>
                      <Col md={5} sm={24}>
                        <h1>{news[0].title}</h1>

                        <Divider />
                      </Col>
                    </Link>
                  )}

                  <Col md={12} sm={24}>
                    <img
                      src={cdnImage(news[0].featuredImage)}
                      style={{
                        minWidth: "100%",
                        maxHeight: "250px",
                        objectFit: "contain",
                        borderRadius: "7px",
                      }}
                      alt={news[0].title}
                    />
                  </Col>

                  <Col md={7} sm={24}>
                    <ScrollContainer
                      className={"adherent-right-list"}
                      hideScrollbars={false}
                    >
                                          {news.slice(1, 4).map((article) => (
                          
                                            article.attachment ? <a href={article.attachment} target='blank'>
                                            <div key={article.title}>
                                              <h2>{article.title}</h2>
                  
                                              <Divider />
                                            </div>
                                          </a> : 
                        <Link to={`/${local}/adherent/news/${article._id}`}>
                          <div key={article.title}>
                            <h2>{article.title}</h2>

                            <Divider />
                          </div>
                        </Link>
                      ))}
                    </ScrollContainer>
                  </Col>
                  <Spacer bottom="100px" />
                </div>
              }
            </Row>
          )}

          <Divider />
        </Container>
      </div>
    </Slide>
  );
}
