import React from 'react'
import '../css/ScrollArrow.css'

export default function ScrollArrow({bottom}) {
    return (
        <div style={{
            position: 'absolute',
            bottom: bottom || 0,
            right: '30px',
            zIndex:9999,
            width:'auto', 
            height:'100px'
        }}>
        <div className='Scroll-arrow'>
            <span/>
            <span/>
            <span/>
        </div>
        </div>
        
    )
}