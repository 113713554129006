import React, { useState, useEffect } from 'react'
import NewsSlider from "../../components/NewsSlider"
import BigTitle from "../../components/BigTitle"
import Spacer from "../../components/Spacer"
import Container from "../../components/Container"


export default function Statistics({ isAdherent, title, subtitle, places, local, cities }) {
    const [stats, setStats] = useState([])

    useEffect(() => {
        if (places && Array.isArray(places)){
            let filtredArticles = places.filter(a => a.tags.indexOf('stats') > -1)
           
            setStats(filtredArticles)
        }
    },[places])
    return (
        <Container>
            
        <BigTitle
        title={'Statistiques'}
      />
            <Spacer top="50px" />
         
        <NewsSlider
              isStat
              data={stats.map(s=>{return {...s, link: `/${local}/adherent/stats/${s._id}`}})}
              height={'200px'}
              
              local={local}
        />
        
            <Spacer bottom="77px" />
            </Container>
    );
}