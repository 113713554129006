import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { Icon } from 'antd'
import { useTranslation } from 'react-i18next'
import {meterDisplay, slugify, cdnImage} from '../helpers'
import {Link} from 'react-router-dom'
import { toggleFavorites } from '../actions/favorites'
import { toggleMiceQuote } from '../actions/mice'
//import { img } from 'react-image'
//import { Skeleton } from 'antd';

import moment from 'moment'
import 'moment/min/locales.min'


function ImageCard({
  isStat,
  eventStart,
  eventEnds,
  isTrending,
  _id,
  category,
  deal,
  isVenu,
  url,
  title,
  city,
  distance,
  height,
  date,
  isExperience,
  toggleFavorites,
  favorites,
  link,
  clearTheme,
  addToTour,
  day,
  place,
  closeDrawer,
  removeATour,
  isMice,
  isDeal,
  toggleMiceQuote,
  quotes,
  local,
  isAdherent,
  attachment,
  cost, 
  specialPrice,
  euro
}) {
  const { t } = useTranslation()
  useEffect(() => {
    if (local !== 'ar') {
      moment.locale(local)
    }
    
  }, [local])
 
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: 15,
          left: "20px",
          width: "20px",
          height: "20px",
          zIndex: 10,
          color: "white",
        
        }}
      >
        
       
        {distance && parseInt(distance) > 0 && <div style={{
         fontWeight:900,
          justifyContent: 'center',
          alignItems: 'center',  display: 'flex'
        }}>{meterDisplay(distance)}</div>}
        {removeATour && typeof removeATour === "function" && (
          <Icon
            type="close"
            style={{ fontSize: "20px",   backgroundColor:'rgb(81 131 167)', padding:'5px', borderRadius:'7px' }}
            onClick={() => removeATour(day, _id)}
          />
        )}
      </div>
      {
        !attachment && <Link
        to={
          link ? link : _id ?  "/" + local + "/" + category + "/" + slugify(title, local) + "/" + _id : '#'
         
        }
      >
      <img
          src={cdnImage(url, true)}
          alt={title}
          className="Top-experiences_slider_image"
            style={{ zIndex: -1, height: height || "350px", objectFit: isStat ? 'scale-down' : 'cover', boxShadow: 'none' }}
            
        />
        
          
        
      </Link>
      }
      
      {
        attachment && <a href={attachment } target='blank'>
      <img
          src={cdnImage(url, true)}
            alt={title}
          className="Top-experiences_slider_image"
          style={{ zIndex: -1, height: height || "350px", objectFit: isStat ? 'scale-down' : 'cover', boxShadow:'none' }}
        />
        
          
        
      </a>
      }
      {
        !deal && 
      <div
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop: "10px",
          position: "relative",
          height: "100%",
          minHeight:'90px'
        }}
      >
        {!isExperience && !deal && (
          <div
            style={{
              position: "absolute",
              top: 15,
             
                right: local === 'ar' ? "" : 0,
                  left:  local === 'ar' ? "5px" : "",
              textAlign: "right",
              width: "50px",
                height: "50px",
              
              zIndex: 1
            }}
          >
         
            
            {!addToTour && !isMice && !isAdherent && _id && (
              <Icon
                type="heart"
                theme={favorites.indexOf(_id) > -1 ? "filled" : ""}
                style={{
                  color: clearTheme ? "white" : "var(--vt-text-color-dark)",
                
                  fontSize: "28px"
                }}
                onClick={() => toggleFavorites(_id)}
              />
            )}

            {addToTour && typeof addToTour === "function" && (
              <Icon
                type="plus-circle"
                style={{
                  color: "var(--vt-text-color-dark)",
                  fontSize: "28px"
                }}
                onClick={() => {
                  addToTour(day, place);
                  closeDrawer();
                }}
              />
            )}
            {isMice && !isVenu && (
              <div  onClick={() => {
                toggleMiceQuote(_id);
              }} style={{marginTop:'-100px',cursor:'pointer',backgroundColor:'var(--vt-text-color-dark)', color:'white', textAlign:'center', padding:'5px', borderRadius:'7px'}}><Icon
              type={
                quotes && quotes.indexOf(_id) > -1
                  ? "minus-circle"
                  : "plus-circle"
              }
              theme={quotes && quotes.indexOf(_id) > -1 ? "twoTone" : ""}
              style={{
                color: "white",
                fontSize: "28px"
              }}
             
                /> {quotes && quotes.indexOf(_id) > -1
                  ? "from "
                  : "to "}Quote</div>
              
            )}
            
          </div>
        )}

        {deal && (
          <div
            style={{
              position: "absolute",
              top: 15,
              right: "0px",
              textAlign: "right",
              width: "50px",
              height: "50px",
              zIndex: 1
            }}
          >
            <span className="imageCard-deal-rabaite">{deal}%</span>
          </div>
        )}

        <div style={{ height: "100%" }}>
          <p
            style={{
              fontSize: "16px",
              fontWeight: 300,
              lineHeight: "20px",
              margin: 0,
              paddingRight:local === "ar" ? "0px" : "25px" ,
              fontFamily: "var(--vt-font-medium)",
              color: clearTheme ? "white" : "var(--vt-text-color-dark)",
           
              direction: local === "ar" ? "rtl" : "ltr" 
            }}
          >
            {title}
          </p>
          <p
            className={"cut-text"}
            style={{
              fontSize: "16px",
              lineHeight: "20px",
              margin: 0,
              opacity: 0.7,
              textTransform:'capitalize',
              color: clearTheme ? "white" : "var(--vt-text-color-grey)",
              direction: local === "ar" ? "rtl" : "ltr" 
            }}
          >
            {city}
            </p>
            
            <div style={{position:'absolute', right:'5px', width:'150px', bottom:'-20px', textAlign:'right'}}>
            { isDeal && cost && specialPrice &&
              <p
              className={"cut-text"}
              style={{
                fontSize: "16px",
                lineHeight: "20px",
                margin: 0,
                opacity: 0.7,
                textTransform:'capitalize',
                color: clearTheme ? "white" : "var(--vt-text-color-grey)",
                direction: local === "ar" ? "rtl" : "ltr",
                textDecoration:'line-through'
              }}
            >
              {cost} {euro ? '€' : 'Dhs'}
            </p>
  
            }

            { isDeal && specialPrice &&
              <p
             
              style={{  lineHeight: "25px",}}
            >
              à Partir de <span    style={{
                fontSize: "23px",
                lineHeight: "25px",
                margin: 0,
                opacity: 1,
                textTransform:'capitalize',
                color: "var(--vt-blue)",
                fontFamily:'var(--vt-font-black)',
                direction: local === "ar" ? "rtl" : "ltr" 
              }}>{specialPrice}</span> {euro ? '€' : 'Dhs'}
            </p>
  
            }
            
            </div>

            


          


          {date && !isDeal && (
            <p
              style={{
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: 200,
                color: "var(--vt-text-color-dark)"
              }}
            >
              <Icon type="calendar" /> {date}
            </p>
          )}
          {eventStart && !isDeal && (
            <p
              style={{
                fontSize: "16px",
                lineHeight: "20px",
                margin: 0,
                fontWeight: 200,
                color: "var(--vt-text-color-dark)",
               direction: local === "ar" ? "rtl" : "ltr" 
              }}
            >
              <Icon type="calendar" /> {moment(eventStart).fromNow()}
            </p>
          )}

          {isTrending &&  <div
            style={{
              position: "absolute",
              top: '15px',
                right: local === 'ar' ? "" : 0,
              left:  local === 'ar' ? 0 : "",
              textAlign: "right",
              width: "50px",
              height: "50px",
              zIndex: 1
            }}
          ><Icon
            type="fire"
           theme={"filled"}
            style={{ fontSize: "27px", color:'#FFC107' }}
            /> {t('popular')}</div>}
        </div>
      </div>

          }
    </div>
  );
}

const mapStateToProps = state => {
    return {
      ...state.Favorites,
      ...state.Mice,
      ...state.Local
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      toggleFavorites: (fav) => dispatch(toggleFavorites(fav)),
      toggleMiceQuote:(quote) =>dispatch(toggleMiceQuote(quote))
  }
  }
  
  const appConnect = connect(mapStateToProps, mapDispatchToProps)(ImageCard)
  
  export default appConnect