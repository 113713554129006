import React, {  useEffect } from "react"
import { connect } from 'react-redux'
//import SliderWithTitle from "../../components/sliderWithTitle"
import AdherentSeo from './AdherentsSeo'
import { setFilters } from '../../actions/filters'
import AdherentNews from './AdherentNews'
import PublicationsAndStudies from './PublicationsAndStudiesFixVercel';
import RequestMaterial from '../MiceContainer/RequestMaterial'
import Statistics from './Statistics'
import PhotoBankBanner from './PhotoBankBanner'
import '../../css/AdherentContainer.css'

//const TourBuilder = React.lazy(() => import('../TourBuilder/index'));

function App({ places, articles, tags, cities, filters, local, setFilters }) {


  useEffect(() => {
    setFilters({ ...filters, layout: 'adherents' })
    // eslint-disable-next-line
  },[setFilters])
  
  return (
    <div>
      <AdherentSeo />
      {places && Array.isArray(places) && tags && (
        <div>
          
          {/**Begin news */}
          <AdherentNews articles={articles} local={local}/>
          {/**End news section */}


          <PublicationsAndStudies
          isAdherent
         key={100+'statistics-SliderWithTitle-Explore'}
         title={'Publications et Études'}
    
         places={articles}
           local={local}
        />
          {/**
          
           <SliderWithTitle
                
          key={100+'Events-SliderWithTitle-adherent'}
          title={'Events'}
          subtitle="Every day is a special one in Tangier, Tetouan & Al Hoceima"
          places={places}
          filter={{ tags: ['evenement'] }}
            tags={tags}
            local={local}
          />
          
          */}
         
           {/**End Events  */}
           
           {/**Stats  */}
          <Statistics
            cities={cities}
            isAdherent
           key={100+'statistics-SliderWithTitle-Explore'}
           title={'Statistiques'}
         
           places={articles}
          
           
             local={local}
           />
            {/**End Stats  */}
            
          
          
          <RequestMaterial cities={cities} local={local} />
          <PhotoBankBanner />

         
        </div>
      )}
    </div>
  );
}


const mapStateToProps = state => {
  return {
    ...state.Places,
    ...state.Tags,
    ...state.Cities,
    ...state.Instagram,
    ...state.Experiences,
    ...state.Filters,
    ...state.Favorites,
    ...state.Local,
    ...state.Articles
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFilters: filter=>dispatch(setFilters(filter))
}
}

const appConnect = connect(mapStateToProps, mapDispatchToProps)(App)

export default appConnect

