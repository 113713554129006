import { API_MICE, JSON_HEADERS } from '../constants'
import { message } from 'antd';

export const SET_MICES = 'SET_MICES'
export const TOGGLE_MICE_QUOTE = 'TOGGLE_MICE_QUOTE'

function setMices(mices) {
    return {
        type: SET_MICES,
        payload: mices
    }
}

export function toggleMiceQuote(mice) {
    return {
        type: TOGGLE_MICE_QUOTE,
        payload: mice
    }
}

// get mices from the server
export function fetchSetMices() {
    return function (dispatch) {
        return fetchApiGetMices()
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setMices(response.data))
                    } else {
                        message.error('Something bad happens. Code: ME01')
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: ME02'))
    }
    
}

function fetchApiGetMices() {
    return fetch(API_MICE,{
        method: "GET",
        headers: {...JSON_HEADERS},
    })
}