import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import history from "../history";
import { Select } from "antd";
import ScrollContainer from 'react-indiana-drag-scroll'

import { SUPPORTED_LOCALS } from "../constants";
import "../css/SubHeader.css";

const { Option } = Select;

function SubHeader({ local, setLocal }) {
  const { t } = useTranslation();

  const proMenuItems = [
    {
      title: t("visitors"),
      link: "/" + local,
      layout: "visitors",
    },
    {
      title: t("proMice"),
      link: "/" + local + "/mice",
      layout: "mice",
    },
    {
      title: t("adherent"),
      link: "/" + local + "/adherent",
      layout: "adherents",
    },
    {
      title: t("press"),
      link: "/" + local + "/press",
      layout: "press",
    },
    {
      title: t("deals"),
      link: "/" + local + "/deals",
      layout: "visitors",
    },
  ];

  return (
    <ScrollContainer>
    <header className="App-header-mobile">
  
       
          {
            proMenuItems.map((p, k) => (
              <div><Link to={p.link}>{p.title}</Link>    </div>
            ))
  }
      
          

    
     
      
          <Select
            defaultValue={local}
            onChange={(loc) => {
              const currentLocation = window.location.pathname;
              const newLocation = currentLocation.replace(
                "/" + local + "/",
                "/" + loc + "/"
              );
              history.replace(newLocation);
              setLocal(loc);
            }}
          >
            {SUPPORTED_LOCALS.map((l, k) => (
              <Option key={l + k} value={l}>
                {l}
              </Option>
            ))}
          </Select>
       
        
      </header>
      </ScrollContainer>
   
  );
}

export default SubHeader;
