import React, { useState } from "react";
import { Form, Icon, Input, Button, message } from "antd";
import Container from "../../components/Container";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContactSeo from './ContactSeo'
import { API_CONTACT, JSON_HEADERS, LOGO_CRT } from "../../constants";
import Spacer from "../../components/Spacer";

import "./Contact.style.css";

const { TextArea } = Input;

function validateEmail(email) {
  const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}


function Contact() {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    name: "",
    from: "",
    message: "",
  });

  const [sent, setSent] = useState(false);

  function submit(form) {
    if (!validateEmail(form.from)) {
      message.error("Email address is invalid");
      return;
    }

    if (!form.name) {
      message.error("Name is required");
      return;
    }

    if (!form.message) {
      message.error("Message is required");
      return;
    }

    fetch(API_CONTACT, {
      method: "POST",
      body: JSON.stringify(form),
      headers: { ...JSON_HEADERS },
    })
      .then(() => {
        //send Email here
        message.success("Thank you for your message");
        setSent(true);
      })
      .catch((e) => message.error("An error occured"));
  }

  return (
    <Container>
      <ContactSeo />
      <div className="contact-us">
        <img src={LOGO_CRT} alt="logo" className="logo" />

        <div className="contact-details">
          <p>Angle Avenue Mohamed VI et Rue Ahfir - Résidence la Marina 2 1er étage Nº5, Tanger 90000, Maroc</p>

          <p>
            <Icon type="phone" theme="twoTone" /> +212 539 34 11 44
          </p>
        </div>

        <div  className="contact-details">
          <Input
            placeholder={t("yourName")}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
          />
          <Input
            placeholder={t("yourEmail")}
            type="email"
            onChange={(e) => setForm({ ...form, from: e.target.value })}
          />
          <TextArea
            placeholder={t("yourMessage")}
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={(e) => setForm({ ...form, message: e.target.value })}
          />
          <Spacer top="20px" />
          <Button  onClick={() => submit(form)}>
            {t("sendRequest")}
          </Button>
        </div>
      </div>

      {sent && <Redirect to="/" />}
    </Container>
  );
}

export default Form.create({ name: "contact_form" })(Contact);
