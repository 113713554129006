import {SET_MICES, TOGGLE_MICE_QUOTE} from '../actions/mice'

const initState = {
  mice: {},
  quotes:[]
}
  
export default function placesReducer (state = initState, action) {
    switch (action.type) {
      case SET_MICES:
        return {
              ...state,
              mice: action.payload,
        }
      
      case TOGGLE_MICE_QUOTE:
        const quote = action.payload
        const quotes = state.quotes
        const isQuoteExist = (state.quotes.indexOf(quote) > -1)
        
        if (isQuoteExist) {
          const filtredQuotes = quotes.filter(f=>f !== quote)
          return {
            ...state,
            quotes: [...filtredQuotes],
          }
        }
        return {
            ...state,
            quotes: [...state.quotes, action.payload],
        }
      
       
      default:
        return state
    }
  }
  