import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Input, Button, Icon, Row, Col, Statistic } from "antd";
import { getSafe } from "../../helpers";
import { local } from "../../translations";
import {
  fetchSetQuotes,
  fetchUpdateQuotes,
  fetchPostQuote,
} from "../../actions/quotes";
import "../../css/QuotesContainer.css";

const { TextArea } = Input;

function QuoteContainer({
  fetchSetQuotes,
  fetchUpdateQuotes,
  fetchPostQuote,
  quotes,
  misc,
  places,
  dealId,
  deals,
  route,
}) {
  const [betterQuotes, setBetterQuotes] = useState([]);
  const [currentDeal, setCurrentDeal] = useState({});
  const routeId = route.match.params.id;
  const routeEmail = route.match.params.email;
  const [userMessage, setUserMessage] = useState();

  useEffect(() => {
    if (Array.isArray(deals) && Array.isArray(places)) {
      setCurrentDeal(
        deals.filter((d) => d._id === dealId)[0]
          ? deals.filter((d) => d._id === dealId)[0]
          : places.filter((d) => d._id === dealId)[0]
      );
    }
  }, [deals, dealId, places]);

  useEffect(() => {
    if (Array.isArray(quotes)) {
      setBetterQuotes(
        quotes.map((q) => {
          return { ...q, id: q.from === routeEmail ? "owner" : "other" };
        })
      );
    }
  }, [quotes, routeEmail]);

  useEffect(() => {
    let timer;
    if (routeId && routeEmail) {
      fetchSetQuotes(routeId);
      timer = setInterval(() => fetchSetQuotes(routeId), 10000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [fetchSetQuotes, routeId, routeEmail]);

  console.log(misc);
  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "40vh",
        paddingTop: "5vh",
      }}
    >
      {betterQuotes && (
        <div>
          {/**Messages list */}
          <div className="quote-messages-container">
          
              <div style={{ textAlign: "center", marginBottom: "50px" }}>
              
              <Row>
                
                {
                  getSafe(() => misc.participants_number) && <Col md={6} sm={24}>
                  <Statistic
                    title="Nombre de participants"
                    value={getSafe(() => misc.participants_number)}
                    prefix={<Icon type="team" />}
                  />
                </Col>
                }

                {
                  getSafe(() => misc.budget) && <Col md={6} sm={24}>
                  <Statistic
                    title="Budget"
                    value={getSafe(() => misc.budget)}
                    prefix={<Icon type="dollar" />}
                  />
                </Col>
                }
                    

                {
                  getSafe(() => misc.from_date) &&  <Col md={6} sm={24}>
                  <Statistic
                    title="À partir de"
                    value={getSafe(() => misc.from_date)}
                    prefix={<Icon type="clock-circle" />}
                  />
                </Col>
                }
                    

                {
                  getSafe(() => misc.to_date) &&   <Col md={6} sm={24}>
                  <Statistic
                    title="Jusqu'au"
                    value={getSafe(() => misc.to_date)}
                    prefix={<Icon type="clock-circle" />}
                  />
                </Col>
                }

                {
                  getSafe(() => misc.adultsNumber) &&   <Col md={6} sm={24}>
                  <Statistic
                    title="Nombre d'adulte"
                    value={getSafe(() => misc.adultsNumber)}
                    prefix={<Icon type="team" />}
                  />
                </Col>
                }


                {
                  getSafe(() => misc.childrenNumber) &&   <Col md={6} sm={24}>
                  <Statistic
                    title="Nombre d'enfant"
                    value={getSafe(() => misc.childrenNumber)}
                    prefix={<Icon type="team" />}
                  />
                </Col>
                }

                {
                  getSafe(() => misc.phone) &&   <Col md={6} sm={24}>
                  <Statistic
                    title="Téléphone"
                    value={getSafe(() => misc.phone)}
                    prefix={<Icon type="phone" />}
                  />
                </Col>
                }


                   
                  
                  </Row>
              
              </div>
         

            <ul style={{ margin: 0, padding: 0 }}>
              <li>
                Vous pouvez fermer cette fenêtre si vous le désirez. Vous
                receverez un email de notification quand il y aura un nouveau
                message de la part du fournisseur.
              </li>

              {currentDeal && (
                <li>
                  Merci pour l'interêt que vous portez à{" "}
                  <strong>{getSafe(() => currentDeal.title[local])}</strong>
                  <br />
                  <img
                    src={getSafe(() => currentDeal.media[0].url)}
                    style={{ height: "240px", width: "auto" }}
                    alt={getSafe(() => currentDeal.title[local])}
                  />
                </li>
              )}

              {betterQuotes &&
                betterQuotes.map((q, k) => (
                  <div className={q.id + "-container"} key={1000 + q.id + k}>
                    <li className={q.id}>{q.message}</li>
                  </div>
                ))}
            </ul>
          </div>

          {/*message input*/}
          <div className="quote-input-container">
            <TextArea
              rows={10}
              value={userMessage}
              placeholder="Type your message here"
              onChange={(e) => setUserMessage(e.target.value)}
            />
            <div className={"send-quote-button"}>
              <Button
                type="primary"
                onClick={() => {
                  if (userMessage) {
                    setUserMessage("");
                    fetchUpdateQuotes({
                      id: routeId,
                      from: routeEmail,
                      message: userMessage,
                    });
                  }
                }}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.Quotes,
    ...state.Places,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSetQuotes: (id) => dispatch(fetchSetQuotes(id)),
    fetchUpdateQuotes: (body) => dispatch(fetchUpdateQuotes(body)), //body = {message, from}
    fetchPostQuote: (body) => dispatch(fetchPostQuote(body)),
  };
};

const appConnect = connect(mapStateToProps, mapDispatchToProps)(QuoteContainer);

export default appConnect;
