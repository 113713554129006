import { SET_INSTAGRAM } from '../actions/instagram'
import initialState from './instagram-initial-state'

const initState = {
    instagram: [...initialState]
}
  
export default function tagsReducer (state = initState, action) {
    switch (action.type) {
      case SET_INSTAGRAM:
            return {
                instagram: action.payload
        }
      default:
        return state
    }
  }
  
