import React from 'react'
import { Tabs } from 'antd';
import Container from '../../components/Container'
import BreadCramb from './Breadcramb'
import Spacer from '../../components/Spacer'
import Visa from '../VisaInfo'
import Seo from './PraticalInfoSeo'
const { TabPane } = Tabs;



export default function GettingHere() {
    return (
        <div className='getting-here-container'>
            <Seo />
            <Spacer top='20px' />
            
            <Container>
                <BreadCramb local='fr' />
                <Spacer top='50px' />
            <Tabs defaultActiveKey="1" >
            <TabPane tab="Heure locale " key="1">
            GMT (Eté du 1/04 au 31/10: +1 heure). Pendant le Ramadan , nous revenons à l’heure officielle GMT.
            </TabPane>
            <TabPane tab="Climat " key="2">
              <p >La région est sous l’influence directe du climat méditerranéen
                        Les températures restent clémentes en hiver, douces en été aussi bien sur les côtes qu’en altitude.
                        Elles descendent rarement sous 0°C en janvier en hauteur et les maxima les plus fréquents de ce mois oscillent entre 14°C et 18°C.
                        En été, l’atmosphère se réchauffe sensiblement, les températures maximales les plus fréquentes en juillet varient entre 16°C et 26°C. Des pics de 32°C à 38°C ont pu être enregistrés quelques jours par an, mais leur fréquence demeure exceptionnelle.
              </p>
                        <p>Prévisions météorologiques:<br/>Tel: 05 22 90 24 22</p>
            </TabPane>
            <TabPane tab="Formalités Visa" key="3">
              <Visa />
                    </TabPane>
                    <TabPane tab="Langues " key="4">
                 <p>A Tanger, Tétouan et à Al Hoceima, on parle plusieurs langues dont l’espagnol, le français ainsi que l’anglais.
                 L’arabe et l’amazigh sont les langues nationales.
                 </p>
                    </TabPane>
                    <TabPane tab="Monnaie " key="5">
                   <p>L’unité monétaire au Maroc est le Dirham (MAD), divisé en 100 centimes. Il existe des billets de 20, 50,100 et 200 DH, et des pièces de 0,5 DH, 5 DH, 1 DH, 2 DH, 5 DH et de 10 DH.</p>
                    <p>Le retrait d’argent avec carte de crédit, chéquier ou distributeur automatique est possible auprès de toutes les banques. Les cartes de crédit peuvent être utilisées dans les grands hôtels, grandes surfaces de distribution, restaurants et certaines boutiques.</p>
                    </TabPane>
                    <TabPane tab="Horaires de travail " key="6">
                    <p>L’horaire du travail dans les administrations et les services publics est continu de 8h à 16h30.
                    Pareil pour bon nombre d’entreprises privées dont les banques.
                    </p>
                    </TabPane>
                    <TabPane tab="Lieux de culte" key="7">
                    <p>Il existe des mosquées dans les principaux quartiers de la ville.
                    </p>
                        <strong>Cathédrale</strong><br />
                        Sidi Bouabid, Tanger Maroc<br />
                        Tél.: 05 39 932 762<br /><br />

                        <strong>Synagogue Chaar Raphaël</strong><br />
                        26 Bd Pasteur, Tanger Maroc<br /><br />
                        
                        <strong>Synagogue Ben Atar</strong><br />
                        1 rue de la liberté, Tanger Maroc<br />

                    </TabPane>
                    <TabPane tab="Urgences" key="8">
                        <p><strong>Police</strong>: Tél. : 19</p>
                        <p><strong>Pompiers </strong>: Tél. : 15</p>
                        <p><strong>Secours routiers </strong>: Tél. : 177</p>
                        <p><strong>Renseignements téléphoniques</strong>: Tél. : 160</p>
                        <p><strong>Hôpital Mohammed V</strong>: Tél.: 05 39 93 42 42 / 05 39 93 24 44 / 05 39 93 10 73</p>
                        <p><strong>Médecin à domicile</strong>: Tél.: 05 39 33 33 00</p>
                        <p><strong>Secours rapide</strong>: Tél.: 05 39 95 40 40</p>
                        <p><strong>Croissant Rouge Marocain</strong>: Tél.: 05 39 35 20 20 </p>

                  </TabPane>

          </Tabs>
            </Container>
            <Spacer top='100px'/>
      
        </div>
    )
}


