import React from 'react'
import Container from '../../components/Container'
import './PrivacyPolicy.style.css'

export default function PrivacyPolicy() {
    return ( <Container>
        <div className={'privacy-policy'}>
           
            <h1>Regional Tourism Council of Tangier Tetouan Al Hoceima</h1>
            <h2>Privacy Policy</h2>
            <p>Effective date: June 21, 2020</p>
            
            <p>Regional Tourism Council of Tangier Tetouan Al Hoceima ("us", "we", or "our") operates
            the official website of the region, and the mobile applications: Visit Tanger, Visit Tetouan,
            Visit Alhoceima and Visit Chefchaouen. Also, the messenger bot that is aLached to its
            Facebook page (the "Service").</p>

            <p>This page informs you of our policies regarding the collection, use, and disclosure of
            personal data when you use our Service and the choices you have associated with
            that data.</p>

            <p>
                We use your data to provide and improve the Service. By using the Service, you agree to
                the collection and use of information in accordance with this policy. Unless otherwise
                defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings
                as in our Terms and Conditions.
            </p>
            
            <p>
                We certify that our website adheres to the conditions set out in the General Data
                Protection Regulations (GDPR)
            </p>
            
            <p>
                We certify that the request for data processing has been addressed to the CNDP
                (Commission Nationale de Contrôle de Protection des Données à Caractère Personnel /
                National Supervisory Commission for the Protection of Personal Data – Morocco)
            </p>
            
            <h3>Information Collection and Use</h3>

            <p>We collect several different types of information for various purposes to provide
            and improve our Service to you and have beLer overview of the trends and
            consuming habits of the potential tourists of our region.</p>

            <h3>Types of Data Collected</h3>

            <h4>Personal Data</h4>

            <p>While using our Service, we may ask you to provide us with certain personally
            identifiable information that can be used to contact or identify you ("Personal Data")ti
            Personally, identifiable information may include, but is not limited to:</p>

            <ul>
            
                <li>
                Email address
                </li>
            
                <li>
                Address, State, Province, ZIP/Postal code, City
                </li>
                <li>
                Cookies and Usage Data
                </li>
                <li>
                Other data that the users communicates voluntarily on our services
                </li>
            </ul>

            <h3>Use of Data</h3>
            <p>Regional Tourism Council of Tangier Tetouan Al Hoceima uses the collected data for
            various purposes:</p>

            <ul>
                <li>To provide and maintain the Service</li>
                <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                <li>To provide analysis or valuable information so that we can improve the Service</li>
                <li>To put in place beLer strategies and plans to develop our region</li>
                <li>To get to know our prospects beLer and have beLer insights</li>
                <li>To share insights and put forward data helping to understand the interaction of the visitors with
                our region</li>
                <li>To monitor the usage of the Service</li>
                <li>To detect, prevent and address technical issues</li>
            </ul>

            <h3>Transfer Of Data</h3>
            <p>Your information, including Personal Data, may be transferred to — and maintained on
            — computers located outside of your state, province, country or other governmental
            jurisdiction where the data protection laws may differ than those from your
            jurisdiction.</p>
            <p>Your use of our services represents your agreement to that transferti
            Regional Tourism Council of Tangier Tetouan Al Hoceima will take all steps reasonably
            necessary to ensure that your data is treated securely and in accordance with this
            Privacy Policy and no transfer of your Personal Data will take place to an organization or
            a country unless there are adequate controls in place including the security of your data
            and other personal information</p>


            <h4>Disclosure of Data</h4>
            <h3>Legal Requirements</h3>
            <p>Regional Tourism Council of Tangier Tetouan Al Hoceima may disclose your Personal Data
            in the good faith belief that such action is necessary to:</p>
            <ul>
                <li>To comply with a legal obligation</li>
                <li>To protect and defend the rights or property of Regional Tourism Council of Tangier Tetouan Al
                Hoceima</li>
                <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>To protect the personal safety of users of the Service or the public</li>
                <li>To protect against legal liability</li>
            
            </ul>

            <h3>Security of Data</h3>
            <p>The security of your data is important to us, but remember that no method of
            transmission over the Internet, or method of electronic storage is 100% secure. While
            we strive to use commercially acceptable means to protect your Personal Data, we
            cannot guarantee its absolute security.</p>
                
            <h3>Service Providers</h3>
            <p>We may employ third party companies and individuals to facilitate our Service
            ("Service Providers"), to provide the Service on our behalf, to perform Service-related
            services or to assist us in analyzing how our Service is usedti
            These third parties have access to your Personal Data only to perform these tasks on
            our behalf and are obligated not to disclose or use it for any other purpose.</p>
            
            <h3>Links To Other Sites</h3>
            <p>Our Service may contain links to other sites that are not operated by us. If you click on
            a third-party link, you will be directed to that third party's site. We strongly advise you
            to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies
            or practices of any third-party sites or services.</p>

            <h3>Changes to This Privacy Policy</h3>
            <p>We may update our Privacy Policy from time to time. We will notify you of any
            changes by posting the new Privacy Policy on our websiteti
            You are advised to review this Privacy Policy periodically for any changes. Changes to
            this Privacy Policy are effective when they are posted on this page.</p>

            <h3>Contact Us</h3>
            <p>If you have any questions about this Privacy Policy, please contact us:</p>
            <ul>
                <li>By email: contact@visittanger.com</li>
                <li>By visiting this page on our website: <a href='https://visittanger.com/en/contact-crt-tta'>https://visittanger.com/en/contact-crt-tta</a></li>
                <li>By phone number: +212 539 341 144</li>
                <li>By mail: Angle Avenue Mohamed VI et Rue Ahfir - Résidence la Marina 2 1er étage Nº5,
                Tanger 90000</li>
              
            </ul>
            
        </div>
        </Container >
    )
}