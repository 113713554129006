import React, { useRef } from "react";
import { getDistance } from "geolib";
import Slider from "react-slick";
import { Button } from "antd";
import ImageCard from "./ImageCard";
import { getSafe, citySlugToName } from "../helpers";

import { SLDIER_SETTINGS, NO_IMAGE } from "../constants";

const ButtonGroup = Button.Group;

export default function ImageSlider({
  data,
  distance,
  date,
  height,
  position,
  clearTheme,
  slides,
  slideWidth,
  local = "en",
  isMice,
  isDeal,
  addToTour,
  day,
  closeDrawer,
  cities,
  euro
}) {
  const slideRef = useRef(null);
  let newSliderSettings = slides
    ? {
        ...SLDIER_SETTINGS,
        slidesToShow: slides,
        slidesToScroll: slides,
        responsive: [],
      }
    : SLDIER_SETTINGS;

  const onNext = () => {
    slideRef.current.slickNext();
  };

  const onPrevious = () => {
    slideRef.current.slickPrev();
  };

  return (
    <div>
    <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "-20px",
    }}
  >
    <ButtonGroup>
          <Button
          shape="circle"
       
        size="default"
        icon="left"
        onClick={onPrevious}
        style={nextPrev}
      />
          <Button
          shape="circle"
        
        size="default"
        icon="right"
        onClick={onNext}
        style={nextPrev}
      />
    </ButtonGroup>
      </div>
      {
        data && Array.isArray(data) && <Slider {...newSliderSettings} ref={slideRef}>
        {data.map((poi, k) => (
          <ImageCard
            euro={ euro}
          place={poi}
          addToTour={addToTour}
          closeDrawer = {closeDrawer}
          day={day}
            isMice={isMice}
            isDeal={isDeal}
            isTrending={poi.isTending}
            clearTheme={clearTheme}
            key={"ImageCard-" + k + "+" + getSafe(() => poi["title"][local])}
            url={poi.media && poi.media[0] ? poi.media[0].url : NO_IMAGE}
            title={getSafe(() => poi["title"][local])}
            category={getSafe(() => poi.tags[0].slug) || "d"}
            city={
              poi.isExperience
                ? ""
                : (getSafe(()=>citySlugToName(poi["contact"]["address"]["city"], cities, local))) 
            }
            distance={
              position && position.latitude && position.longitude
                ? getDistance(
                    { ...position },
                    {
                      latitude: poi.location.coordinates[1],
                      longitude: poi.location.coordinates[0],
                    }
                  )
                : null
            }
            height={height}
            date={date}
            eventStart={getSafe(() => poi.misc.eventStartDate)}
            eventEnds={getSafe(() => poi.misc.eventEndDate)}
            cost={getSafe(() => poi.misc.cost)}
            specialPrice={getSafe(() => poi.misc.specialPrice)}
            _id={poi._id}
            link={poi.link}
            isExperience={poi.isExperience}
          />
        ))}
      </Slider>
      }
      

     
    </div>
  );
}

const nextPrev = {
  margin: "5px",
  marginTop: 0,
};
