import { API_STATS, JSON_HEADERS } from '../constants'

export const SET_PLACES_STATS = 'SET_PLACES_STATS'
export const SET_TOURS_STATS = 'SET_TOURS_STATS'


function setPlacesStats(places) {
    return {
        type: SET_PLACES_STATS,
        payload: places
    }
}

function setTourStats(places) {
    return {
        type: SET_TOURS_STATS,
        payload: places
    }
}


// get cities from the server
export function fetchSetPlacesStats() {
    return function (dispatch) {
        return fetchApiGetPlacesStats()
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setPlacesStats(response.data))
                    } else {
                        console.error('Something bad happens. Code: StatsTE01')
                    }
                })
                .catch(e=>console.error('Something bad happens. Code: StatsT02'))
    }
    
}


export function fetchSetToursStats() {
    return function (dispatch) {
        return fetchApiGetToursStats()
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setTourStats(response.data))
                    } else {
                        console.error('Something bad happens. Code: StatsE01')
                    }
                })
                .catch(e=>console.error('Something bad happens. Code: Stats02'))
    }
    
}


function fetchApiGetPlacesStats() {
    return fetch(API_STATS+'/popular_places' ,{
        method: "GET",
        headers: {...JSON_HEADERS},
    })
}


function fetchApiGetToursStats() {
    return fetch(API_STATS+'/popular_tours' ,{
        method: "GET",
        headers: {...JSON_HEADERS},
    })
}