export const SET_FILTERS = 'SET_FILTERS'

export function setFilters(filters) {
   /**
    * Example filters
    *  { 
    *       tags: [],
    *       citites:[],
    *       keyword:,
    *       startingPoint: [lon, lat],
    *       budget:,
    *       fromDate:,
    *       toDate:,
    *  }
    */
    return {
        type: SET_FILTERS,
        payload: filters
    }
}