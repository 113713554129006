import { SET_EXPERIENCES } from '../actions/experiences'

const initState = {
    experiences: []
}
  
export default function experiencesReducer (state = initState, action) {
    switch (action.type) {
      case SET_EXPERIENCES:
            return {
              experiences: action.payload
        }
      default:
        return state
    }
  }
  
