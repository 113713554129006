import { TOGGLE_FAVORITES } from '../actions/favorites'

const initState = {
    favorites: []
}
  
export default function tagsReducer (state = initState, action) {
    switch (action.type) {
        case TOGGLE_FAVORITES:
            const favorite = action.payload
            const favorites = state.favorites
            const isFavoriteExist = (state.favorites.indexOf(favorite) > -1)
            
            if (isFavoriteExist) {
              const filtredFavorites = favorites.filter(f=>f !== favorite)
              return {
                ...state,
                favorites: [...filtredFavorites],
              }
            }
            return {
                ...state,
                favorites: [...state.favorites, action.payload],
            }
  
        default:
            return state
    }
  }
  
