import React from 'react'
import { connect } from 'react-redux'
import { BackTop } from 'antd'
import {setLocal} from '../actions/local'
import MainHeader from './MainHeaderMice'
import SubHeader from './SubHeader'
import Spacer from './Spacer'
//import FooterFilters from './FooterFilters'

import Footer from './Footer'

function Layout({ bottom, children,  setLocal, filters,  noCustomHeader, local }) {
    return (
        <div className="App" style={{direction: local === 'ar' ? 'rtl' : 'ltr'}}>
            <BackTop />
            <SubHeader local={local} setLocal={setLocal} isMice filters={filters}/>
            <MainHeader />

          
         
            <div style={{minHeight:'100vh'}}>
            
            {children}
            </div>
           

            {
                (window.location.pathname.split('/').indexOf('quotes') === -1) && <Footer />
           }
            
            {
                bottom && <Spacer bottom={bottom}/>
            }
            {/*<FooterFilters tags={tags}/>*/}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        ...state.Places,
        ...state.Tags,
        ...state.Filters,
        ...state.Instagram,
        ...state.Local
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLocal:(local)=> dispatch(setLocal(local))
    }
  }
  
const appConnect = connect(mapStateToProps, mapDispatchToProps)(Layout)

export default appConnect
  