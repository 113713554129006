import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { BackTop } from 'antd'
import ReactGA from "../googleAnalytics";
import { useHistory } from 'react-router-dom'
import {setLocal} from '../actions/local'
import MainHeader from './MainHeader'
import SubHeader from './SubHeader'
import Spacer from './Spacer'
import CustomisationHeader from './CustomisationHeader'
//import FooterFilters from './FooterFilters'

import Footer from './Footer'

function Layout({ bottom, children, setLocal, filters, noCustomHeader, local }) {
  const history = useHistory() 

  useEffect(() => {
    try {
      ReactGA.set({ page: window.location.pathname });
      ReactGA.pageview(window.location.pathname);
    } catch (error) {
      
    }
   
  }, [])
  

  useEffect(() => {
    return history.listen((location) => { 
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }) 
  }, [history]) 
  
  
   
    
    return (
      <div
        className="App"
        style={{ direction: local === "ar" ? "rtl" : "ltr" }}
      >
        <BackTop />
        <SubHeader local={local} setLocal={setLocal} filters={filters}/>
        <MainHeader />

        {((filters && filters.tags && filters.tags.length > 0) ||
          (filters && filters.cities && filters.cities.length > 0)) &&
          !noCustomHeader && <CustomisationHeader />}
        <div style={{ minHeight: "100vh" }}>{children}</div>

        {window.location.pathname.split("/").indexOf("quotes") === -1 && (
          <Footer local={local}/>
        )}

        {bottom && <Spacer bottom={bottom} />}
        {/*<FooterFilters tags={tags}/>*/}
      </div>
    );
}

const mapStateToProps = state => {
    return {
        ...state.Places,
        ...state.Tags,
        ...state.Filters,
        ...state.Instagram,
        ...state.Local
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLocal:(local)=> dispatch(setLocal(local))
    }
  }
  
const appConnect = connect(mapStateToProps, mapDispatchToProps)(Layout)

export default appConnect
  