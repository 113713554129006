import React from 'react'
import { Icon } from 'antd'
import Slide from 'react-reveal/Slide';
import Spacer from './Spacer'

export default function MegaMenuContainer({ children, showMenu, setShowMenu }) {
    return (
      <Slide top>
        <div
          className={"mega-menu-modal"}
          style={{
            display: showMenu ? "block" : "none"
          }}
        >
          <div className="mm-close-btn" onClick={() => setShowMenu(false)}>
            <Icon type="close" style={{ color: "white", fontSize: "30px" }} />
          </div>

          <Spacer top="77px" />
          {children}
        </div>
      </Slide>
    );
}