import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Tabs } from 'antd';
import { Row, Col, Breadcrumb, Button, Icon, message } from 'antd'
import DetailSeo from './PlaceDetailSeo'
import { useTranslation } from 'react-i18next'
import ImageCard from '../../components/ImageCard'
//import Layout from '../../components/Layout'
import Container from '../../components/Container'
import FindLocalPartner from '../../components/FindLocalPartner'
import ScrollArrow from '../../components/ScrollArrow'
import Slider from 'react-slick'
import SliderWithTitle from "../../components/sliderWithTitle"
import Spacer from '../../components/Spacer'
import Nearby from '../../components/Nearby'
import {toggleMiceQuote} from '../../actions/mice'
import { getSafe, copyTextToClipboard, cdnImage, feedBi, citySlugToName, phoneNumberNormalizer, betterGroupBy } from '../../helpers'
//import {CONTACT_ARRAY} from '../../constants'
import '../../css/PlaceDetailContainer.css'

const sliderSettings = {
  dots: true,
  arrows: false,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const { TabPane } = Tabs;

function PlaceDetailContainer({ places, user, deals, route, tags, experiences, isDeal, isMice, local, toggleMiceQuote, quotes, mice, filters, cities }) {

  const {t} = useTranslation()  
  const routeId = getSafe(() => route.match.params.id)
  const [currentPlace, setCurrentPlace] = useState({})
  const [currentTags, setCurrentTags] = useState()
  const [currentMice, setCurrentMice] = useState({})
  const [relatedCurrentVenuMice, setRelatedCurrentVenuMice] = useState([])
  const [automaticIsMice, setAutomaticIsMice] = useState(false)
   // eslint-disable-next-line
  const [isVenu, setIsVenu] = useState(false)

  // Statistics
  useEffect(() => {
    if (currentPlace && currentPlace._id) {
      feedBi({
        ...user, 
        place: currentPlace._id
      })
    }
  }, [currentPlace, user])


  //set venu
  useEffect(() => {
    if (currentMice && currentMice.cat && currentMice.cat[0]) {
      setIsVenu( currentMice.cat[0] === 'venu')  
    }
    
  }, [currentMice])
  
  //set current mice
  useEffect(() => {
   
    if (Array.isArray(mice) && currentPlace && currentPlace._id ) {
      const currentMice = mice.filter(m => m.place._id === currentPlace._id)
      if (currentMice && currentMice[0] && currentMice[0]._id) {
       setCurrentMice(currentMice[0])
     }
    }
    
  }, [currentPlace, mice])
  
    //set related  mices when venu
    useEffect(() => {
   
      try {
        if (Array.isArray(mice) && currentMice && currentMice.cat && currentMice.cat[0]==='venu' ) {
          const relatedCurrentVenus = mice.filter(m => m.place._id === currentMice.place._id)
          if (relatedCurrentVenus && relatedCurrentVenus[0]) {
            const allShapes = relatedCurrentVenus.map(v=>{return {...v, layout:v.layout[0]}})
            const relatedGroupedLayouts = betterGroupBy('layout')(allShapes)
         
            setRelatedCurrentVenuMice(relatedGroupedLayouts)
         }
        }
      } catch (error) {
        console.log(error)
      }
    
      
    },[currentMice, mice])

  

  useEffect(() => {
  
    if (isMice || filters.layout === 'mice') {
      setAutomaticIsMice(true)
    }
  
  },[filters, isMice])

  //set current place
    useEffect(() => {

      let thisPlace = (places && Array.isArray(places)) ? places.filter((p) => p._id === routeId)[0] : {}
      thisPlace = (deals && deals.filter((p) => p._id === routeId)[0]) ? deals.filter((p) => p._id === routeId)[0] : thisPlace
       
        if (thisPlace) {
            setCurrentPlace(thisPlace)
        }

   

    }, [routeId, places, setCurrentPlace, deals, isDeal])

    useEffect(() => {
        if (currentPlace && tags) {
            const thisSlug = getSafe(()=> currentPlace.tags[0].slug)
            setCurrentTags(tags.filter(t => t.slug === thisSlug)[0])
           
        }
      
    }, [currentPlace, tags])
  
  
    
    return (
      <div>
        <DetailSeo title={getSafe(() => currentPlace.title[local])} description={getSafe(() => currentPlace.description[local])}/>
        <ScrollArrow />
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            width: "100vw",
            height: "100%",
            zIndex: -1,
            backgroundColor: "black"
          }}
        >
          <img
            alt="bg"
            src={getSafe(() => currentPlace.media[0].url)}
            style={{
              filter: "blur(15px)",
              width: "100%",
              height: "100%",
              opacity: 0.5
            }}
          />
        </div>
        {
          automaticIsMice && relatedCurrentVenuMice && 
          <Row gutter={12} style={{backgroundColor:'white', padding:'20px'}}>
        <Tabs defaultActiveKey="1" >
      {automaticIsMice && relatedCurrentVenuMice && Object.keys(relatedCurrentVenuMice).map((key,i) =>

        (

         
          <TabPane tab={key} key={i+1}>
          {relatedCurrentVenuMice[key].map(r => (

            <Col md={8} key={r._id+'venues'}>
            <div className='venu-container'>
            
              <span>{t('capacity')}</span>
              <h2>{r.capacity} <Icon type="team" /></h2>
            </div>
           
          
          </Col>
))}
          </TabPane>
        
          
     
          

            
        
          
       
         
       
        )
       
      
            )}
            </Tabs>
      </Row>
        }
        
        <Spacer top="20px" />
        <div id="PlaceDetailContainer">
          
          
          <Container>
            
        
          
        
          

            
            
            <Row gutter={24}>
              <Col md={14}>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/" style={{ color: "white" }}>
                      {t('home')}
                    </Link>
                  </Breadcrumb.Item>
                
                  <Breadcrumb.Item>
                    <Link
                      to={
                        "/"+local+"/explore/" + getSafe(() => currentPlace.tags[0].slug)
                      }
                      style={{ textTransform: "capitalize", color: "white" }}
                    >
                      {getSafe(() => currentTags.name[local])}
                    </Link>
                  </Breadcrumb.Item>
                  {
                    isDeal && <Breadcrumb.Item>
                    <Link
                      to={
                        "/"+local+"/deals"
                      }
                      style={{ textTransform: "capitalize", color: "white" }}
                    >
                     Deals
                    </Link>
                  </Breadcrumb.Item>
                  }
                </Breadcrumb>
                <Spacer top="50px" />

                <h1>{getSafe(() => currentPlace.title[local])}</h1>
                <span className="address">
                  {getSafe(() => currentPlace.contact.address.street_address)} <br/>
                  {citySlugToName(getSafe(()=>currentPlace.contact.address.city), cities, local)}
                 
                </span>
                <p>{getSafe(() => currentPlace.description[local])}</p>
              </Col>

              <Col
                md={10}
                style={{
                  textAlign: "right",
                  flexDirection: "row",
                  justifyContent: "flex-end"
                }}
              >
                
              <div style={{marginLeft:'20px'}}>
              {
                currentPlace.media &&  <Slider {...sliderSettings}>
                  {currentPlace.media.map((e, k) => (
                    
                    <ImageCard url={cdnImage(e.url)} height={'250px'} key={k+'sliderDetails'}/>
                  
                ))}
              </Slider>
              }
           
              
              
              
            </div>
                
              {isDeal && (
                  <div>
                    <Link to={ "/"+local+'/quotes/new/'+currentPlace._id}>
                  <Button
                      className="meta"
                      type='primary'
                    
                  >
                    Contacter le fournisseur
                  </Button>
                  </Link>
                </div>
                )}
                
                {(automaticIsMice && filters && filters.layout === 'mice' && quotes.indexOf(currentPlace._id) === -1)   && (
                  <div>
                   
                  <Button
                      className="meta"
                      type='primary'
                      onClick={() => {
                        message.success(`${getSafe(()=>currentPlace.title[local])} added successfully`)
                        toggleMiceQuote(currentPlace._id)
                      }}
                    
                  >
                      {t('addToQuote')}
                  </Button>
                  
                </div>
                )}
                
                {(automaticIsMice && quotes.indexOf(currentPlace._id) > -1) && (
                  <div>
                  <Link to={ "/"+local+'/mice/quote'}>
                  <Button
                      className="meta"
                      type='danger'
                     
                    
                  >
                    Request a proposal
                  </Button>
                      </Link>
                  
                </div>
              )}

              
                {getSafe(() => currentPlace.location.coordinates) && (
                  <div>
                    <Button
                      className="meta"
                      target="Blank"
                      href={
                        "https://www.mapquest.com/latlng/" +
                        currentPlace.location.coordinates[1] +
                        "," +
                        currentPlace.location.coordinates[0]
                      }
                    >
                      <Icon type="compass" /> Navigate
                    </Button>
                  </div>
                )}
                {getSafe(() => currentPlace.misc.eventStartDate) && (
                  <div>
                    <Button
                      className="meta"
                    
                    >
                      <Icon type="calendar" /> {currentPlace.misc.eventStartDate.split('T')[0]}
                    </Button>
                  </div>
                )}
                {getSafe(() => currentPlace.misc.eventEndDate) && (
                  <div>
                    <Button
                      className="meta"
                    
                    >
                      <Icon type="calendar" /> {currentPlace.misc.eventEndDate.split('T')[0]}
                    </Button>
                  </div>
                )}
  
  
                {getSafe(() => currentPlace.contact.email) && (
                  <div>
                    <Button
                      className="meta"
                      onClick={() => {
                        copyTextToClipboard(getSafe(() => currentPlace.contact.email))
                        message.success(getSafe(() => currentPlace.contact.email) +' copied 🎉')
                      }
                     
                      }
                    >
                      <Icon type="form" /> {getSafe(() => currentPlace.contact.email)}
                    </Button>
                  </div>
                )}

                {getSafe(() => currentPlace.contact.phone) && (
                  <div>
                    <Button className="meta">
                      <Icon type="phone" />{" "}
                      {  phoneNumberNormalizer(getSafe(()=>currentPlace.contact.phone))}
                    </Button>
                  </div>
                )}

                {getSafe(() => currentPlace.contact.url) && (
                  <div>
                    <Button
                      className="meta"
                      href={currentPlace.contact.url}
                      target="Blank"
                      rel='no-follow'
                    >
                   www
                    </Button>
                  </div>
                )}

                {getSafe(() => currentPlace.misc.cost) && (
                  <div>
                    <Button className="meta">
                      <Icon type="wallet" />{" "}
                      {getSafe(() => currentPlace.misc.cost)} dhs ≈{" "}
                      {(getSafe(() => currentPlace.misc.cost) / 11).toFixed(2)}{" "}
                      Euros
                    </Button>
                  </div>
                )}

                {getSafe(() => currentPlace.misc.priceRange) && (
                  <div>
                    <Button className="meta">
                      {" "}
                      {getSafe(() => currentPlace.misc.priceRange)}
                    </Button>
                  </div>
                )}

                {getSafe(() => currentPlace.misc.suitableForDisabled) && (
                  <div>
                    <Button className="meta">
                      <span role="img" aria-label="wheelchair">
                        ♿
                      </span>
                    </Button>
                  </div>
                )}

                {getSafe(() => currentPlace.misc.wifi) && (
                  <div>
                    <Button className="meta">
                      <Icon type="wifi" />
                    </Button>
                  </div>
                )}

                {getSafe(() => currentPlace.misc.alcool) && (
                  <div>
                    <Button className="meta">
                      {" "}
                      <span role="img" aria-label="cocktail">
                        🍸
                      </span>
                    </Button>
                  </div>
                )}

              

              </Col>
            </Row>
            
          </Container>

          <Spacer bottom="50px" />
        </div>
      

        {
          !isDeal && !automaticIsMice && <SliderWithTitle
            clearTheme
            title={t('topExperience')}
            places={places}
            experiences={experiences}
            local={local}
        />
        }
        
        {
          !isDeal &&  !automaticIsMice && <Nearby
          nearbyPosition={getSafe(() => currentPlace.location.coordinates)}
          places={(places && Array.isArray(places)) ? places.filter(p => p._id !== currentPlace._id) : []}
            tags={tags}
            user={user}
            clearTheme
            local={local}
        />
        }

      
        {
          !automaticIsMice &&   <SliderWithTitle
          clearTheme
          title={t('similar')}
          places={places}
          filter={{ tags: [currentTags ? currentTags.slug : ""] }}
          tags={tags}
          local={local}
        />
        }

        

        {
          <FindLocalPartner clearTheme places={mice ? mice.map((m) => {
            return {
              ...m,
              ...m.place,
            }
          }) : []} local={local} isMice />
        }

      

        <Spacer bottom="50px" />
      </div>
    );
} 


const mapStateToProps = state => {
    return {
      ...state.Places,
      ...state.Tags,
      ...state.Cities,
      ...state.Experiences,
      ...state.Local,
      ...state.Mice,
      ...state.Filters,
      ...state.User
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      toggleMiceQuote:(quote) =>dispatch(toggleMiceQuote(quote))
  }
  }
  
  const appConnect = connect(mapStateToProps, mapDispatchToProps)(PlaceDetailContainer)
  
  export default appConnect;