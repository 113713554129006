import React, {useState, useEffect} from 'react'
import { Select, Icon } from 'antd';
import { useTranslation } from 'react-i18next'
import Container from '../../components/Container'
import Spacer from '../../components/Spacer'
import BigTitle from '../../components/BigTitle'
import visaDb from './morocco.json'
import Seo from './VisaInfoSeo'
import './VisaInfo.style.css'
const { Option } = Select;

function VisaInfo() {
    const { t } = useTranslation()
    const [selected, setSelected] = useState()
    const [results, setResults] = useState()

    const resultFormatter = (r, from) => {
        if (r === 'VR') return t('visaRequired')
        return r + t('daysWithoutVisa')
    }

    useEffect(() => {
        if (selected) {
            const vRequirements = visaDb.filter(v => v.Passport.toLowerCase() === selected.toLowerCase())[0]
            if (vRequirements && vRequirements.Code) {
                setResults(vRequirements.Code)
            }
        }
    },[selected])
    return (
        <div>
            <Seo />
            <Spacer top='100px' />
            <Container>
                <BigTitle title={t('verifyIfVisaReq')}/>
                <Spacer bottom='50px' />
                <div className='visaInfo-container'>
                
                <Select
                showSearch
                style={{ width: '90%' }}
                placeholder={t('selectNationality')}
                optionFilterProp="children"
                onChange={(val)=>setSelected(val)}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                    >
                        
                        
                        {
                            visaDb.map((c, i) => {
                                return <Option key={i+'visa-info'} value={c.Passport}>{c.Passport}</Option>
                            })
                    }
        
              </Select>
                
                </div>
                <div className='visaInfo-results'>
                
                    {results === 'VR' ? <Icon type="warning" /> : results ? <Icon type="check-circle" className='successIcon' /> : ''} {results && resultFormatter(results, selected)}
                    
                    
                </div>
                <Spacer top='50px' />
                {results === 'VR' && <p style={{ textAlign: 'center' }}><a href="https://www.consulat.ma/fr/visitez-le-maroc" target='blank' rel='no-follow'>{t('howGetVisa')}</a></p>}
            </Container>
      
        
        </div>
    )
}



export default VisaInfo