import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'
import BigTitle from "./BigTitle";
import Container from "./Container";
import Spacer from "./Spacer";
import ImageSlider from "./MiceImageSlider";
import "../css/Nearby.css";

const VENU_CAT = "venu";
const ACCOMODATION_CAT = "accomodation";
const SERVICE_CAT = "service";
const DMC = "dmc";



export default function Nearby({ places, clearTheme, local, isMice, filters }) {
  const { t } = useTranslation()
  const [selectedCat, setSelectedCats] = useState({ id: 1, tags: VENU_CAT });
  const [selectedPlaces, setSelectedPlaces] = useState(places);

  useEffect(() => {
    const placesByTags = places.filter(place => {
      if (place.cat) {
        return place.cat.indexOf(selectedCat.tags) > -1;
      }
      return false
    });

    setSelectedPlaces(placesByTags);
  }, [places, selectedCat]);

  return (
    <div id="Nearby-component">
      {places && Array.isArray(places) && (
        <Container>
          <div id="Tour-builder">
            <BigTitle clearTheme={clearTheme} title={t('localPartners')} />
          <Spacer bottom={'20px'} />
            <div className="Nearby-wrapper">
              <div
                className={
                  selectedCat.id === 1
                    ? "Nearby-filters-selected"
                    : clearTheme
                    ? "Nearby-filters-clear"
                    : "Nearby-filters"
                }
                onClick={() => setSelectedCats({ id: 1, tags: VENU_CAT })}
              >
                <h3>{t('venu')}</h3>
              </div>
              <div
                className={
                  selectedCat.id === 2
                    ? "Nearby-filters-selected"
                    : clearTheme
                    ? "Nearby-filters-clear"
                    : "Nearby-filters"
                }
                onClick={() =>
                  setSelectedCats({ id: 2, tags: ACCOMODATION_CAT })
                }
              >
                <h3>{t('accomodation')}</h3>
              </div>
              <div
                className={
                  selectedCat.id === 3
                    ? "Nearby-filters-selected"
                    : clearTheme
                    ? "Nearby-filters-clear"
                    : "Nearby-filters"
                }
                onClick={() => setSelectedCats({ id: 3, tags: SERVICE_CAT })}
              >
                <h3>{t('service')}</h3>
              </div>
              <div
                className={
                  selectedCat.id === 4
                    ? "Nearby-filters-selected"
                    : clearTheme
                    ? "Nearby-filters-clear"
                    : "Nearby-filters"
                }
                onClick={() => setSelectedCats({ id: 4, tags: DMC })}
              >
                <h3>{t('dmc')}</h3>
              </div>
            </div>

            <Spacer top="67px" />
            <ImageSlider
              clearTheme={clearTheme}
              data={selectedPlaces}
              height="350px"
              local={local}
              isMice={isMice}
              isVenu = {VENU_CAT === selectedCat.tags}
            />
          </div>
        </Container>
      )}

    
    </div>
  );
}
