import React, {useState, useEffect} from 'react'
import Slide from 'react-reveal/Slide';
import SocialIcons from './SocialIcons'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import history from '../history'
import { Select } from 'antd';
import { SUPPORTED_LOCALS, API_ENDPOINT } from '../constants'
import menu_dots from '../images/menu_dots.svg'
import globe from '../images/globe.png'
import '../css/SubHeader.css'

const { Option } = Select;

function SubHeader({ local, setLocal, isMice, filters }) {
  const [layout, setLayout] = useState()
  const { t } = useTranslation();
  
  useEffect(() => {
    if (filters && filters.layout) {
      setLayout(filters.layout)
    }
  }, [filters])
  
  const proMenuItems = [
    {
      title:t("visitors"),
      link: '/' + local, 
      layout: 'visitors'
  },
      {
        title: t("proMice"),
        link:'/' + local +'/mice',
        layout:'mice'
    },
    {
      title: t("adherent"),
      link: '/' + local + '/adherent',
      layout:'adherents'
    },
      {
        title: t("press"),
        link:'/' + local + '/press',
        layout:'press'
      },
    {
      title: t("openData"),
      url: API_ENDPOINT+'/api-docs/'
    },
    
    {
      title: t("contact"),
      link: '/' + local + '/contact-crt-tta',
      layout:'contact'
    }
  ]

 

  return (
    <Slide top>
      <header className="App-header-pro" style={{backgroundColor: isMice ? '#202326' : 'var(--vt-blue-sec)'}}>
      <div className="App-wrapper">
      <div>
            <img src={menu_dots} style={{height:'17px'}} alt='menu_dots'/>
      </div>

      <div style={{ width:'100%'}}>
        <div className="Main-menu-wrapper">
          {
            proMenuItems.map((m,k) => (
               <div key={k+'proMenuItems'}> 
                 
                {
                m.link ? <Link to={m.link || '/#'} >
                <div className={ (layout === m.layout) ? 'Secondary-menu-item active' : 'Secondary-menu-item'} key={k}>
                      <span className='Pro-menu-text'> {m.title} </span>
                      </div>
                  
                  
                
                </Link> : null
                }

                {
                  m.url ?  <a href={m.url} target={'blank'}>
                    <div className='Secondary-menu-item' key={k}>
                    <span className='Pro-menu-text'>
                        {m.title}
                        </span>
                  </div>
                </a> : null
                }
               
               </div>
                   
                
                
              
               
            )
              
            )
          }
          
         
        
         
        </div>
      
      </div>
      
        <div className={"menu-icons-right"}>
            <div style={local === 'ar' ? { marginLeft: '50px' } : { marginRight: '50px' }}>
          <SocialIcons />
          </div>
          <div className="menu-icons-right">
          <img src={globe} style={{height:'17px'}} alt='globe'/>
            <Select defaultValue={local}  onChange={(loc) => {
              const currentLocation = window.location.pathname
              const newLocation = currentLocation.replace('/'+local+'/','/'+loc+'/' )
              history.replace(newLocation)
              setLocal(loc)
            }}>
              {
                SUPPORTED_LOCALS.map((l, k) => <Option key={l+k} value={l}>{l}</Option>)
              }
            
            
            </Select>
       
          </div>
          
       
      
      </div>
      
     
      
    </div>

      </header>
      </Slide>
  );
}

export default SubHeader;
