export default [
  "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1592791678015.jpeg",
  "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1592791680736.jpeg",
  "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1592791684127.jpeg",
  "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1592791687035.jpeg",
  "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1592791689970.jpeg",
  "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1592791695884.jpeg",
  "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1592791698937.jpeg",
  "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1592791702112.jpeg",
  "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1592791704716.jpeg",
  "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1592791707066.jpeg",
  "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1592791709740.jpeg",
  "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1592791716009.jpeg"
]