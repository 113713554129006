import React, { useState } from "react";
import { connect } from "react-redux";
import { message, Badge } from "antd";
import useScroll from "../hooks/useScroll";
import isMobile from "ismobilejs";
import MobileMenu from "./MobileMenu";
import SubMenuExplore from "./SubMenuExplore.js";
import SubMenuMobile from "./SubMenuMobile.js";
import { useTranslation } from "react-i18next";
import SubMenuPlan from "./SubMenuPlan.js";
import { Link } from "react-router-dom";
import logo from "../images/logo_colors.png";
import search from "../images/search-dark.png";
import exploreIcon from "../images/explore-icon.png";
import planIcon from "../images/plan-icon.png";
import dealsIcon from "../images/cashier.svg";
import "../css/MainHeaderMice.css";

const RenduMenuElement = ({ menu }) => {
  return (
    <div
      className={menu.isSpecial ? "Main-menu-item special" : "Main-menu-item"}
      style={{
        backgroundColor: menu.isSpecial ? "#F44336" : "",
        borderBottom: menu.isSpecial ? "3px solid var(--vt-blue)" : "",
      }}
    >
      <div className="icon-holder">
        {menu.badge && menu.badge > 0 ? (
          <Badge count={menu.badge}>
            <img src={menu.icon} className="menu-icon-image" alt={menu.title} />
          </Badge>
        ) : (
          <img src={menu.icon} className="menu-icon-image" alt={menu.title} />
        )}
      </div>
      <span className="Main-menu-text">{menu.title}</span>
    </div>
  );
};

const RenderMenuItem = ({ menu, setShowMenu }) => {
  if (menu.disabled) {
    return (
      <div
        className="Main-menu-item"
        style={{ opacity: 0.3 }}
        onClick={() =>
          message.error("Add a least one place to Quote")
        }
      >
        <RenduMenuElement menu={menu} />
      </div>
    );
  }
  if (menu.link) {
    return (
      <Link to={menu.link} className="Main-menu-item">
        <RenduMenuElement menu={menu} />
      </Link>
    );
  } else {
    return (
      <div className="Main-menu-item" onClick={() => setShowMenu(menu.slug)}>
        <RenduMenuElement menu={menu} />
      </div>
    );
  }
};

function MainHeader({ quotes, instagram, local, cities, tags }) {
  const { t } = useTranslation();
  const { scrollDirection, scrollY } = useScroll();
  const [showMenu, setShowMenu] = useState(false);

  const menuItems = [
    {
      title: t('explore'),
      icon: exploreIcon,
      slug: "explore",
    },
    {
      title: t("plan"),
      icon: planIcon,
      slug: "plan",
    },
  ];

  return (
    <div>
      <header
        className={
          scrollDirection === "up" && scrollY > 1800 && !isMobile().phone
            ? "App-header-classic-mice App-header-classic-mice-fixed"
            : isMobile().phone
            ? "App-header-classic-mice App-header-classic-mice-mobile"
            : "App-header-classic-mice"
        }
      >
        <div className="App-wrapper">
          <div className="center-image">
            <Link to={`/${local}/mice`}>
              <img src={logo} className="App-logo-white" alt="App-logo-white" />

           
            </Link>
          </div>

          <MobileMenu color={"black"} setShowMenu={setShowMenu} />

          <div style={{ width: "100%", zIndex: 999999 }}>
            <div className="Main-menu-wrapper">
              {menuItems.map((m, k) => (
                <RenderMenuItem
                  setShowMenu={setShowMenu}
                  menu={m}
                  key={"menu-100" + k}
                />
              ))}

              <RenderMenuItem
                menu={{
                  title: t("smartQuotation"),
                  link: "/" + local + "/mice/quote",
                  icon: dealsIcon,
                  badge: quotes ? quotes.length : 0,
                  disabled: !quotes || quotes.length === 0,
                  isSpecial: true,
                }}
              />
            </div>
          </div>

          <div className="menu-icons-right">
            
          <Link to={`/${local}/search`}>
          <img src={search} className="header-icons" alt="search" />
          </Link>
     
          </div>
        </div>
      </header>

      {showMenu === "explore" && (
        <SubMenuExplore
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          instagram={instagram}
          local={local}
          cities={cities}
          tags={tags}
        />
      )}

      {showMenu === "plan" && (
        <SubMenuPlan
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          instagram={instagram}
          local={local}
          cities={cities}
          tags={tags}
        />
      )}

      {showMenu === "Mobile" && (
        <SubMenuMobile
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          instagram={instagram}
          local={local}
          cities={cities}
          tags={tags}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.Favorites,
    ...state.Instagram,
    ...state.Local,
    ...state.Mice,
    ...state.Cities,
    ...state.Tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const appConnect = connect(mapStateToProps, mapDispatchToProps)(MainHeader);

export default appConnect;
