import React, { useState, useEffect } from "react";
import LazyLoad from "react-lazyload";
import Fade from "react-reveal/Fade";
import Spacer from "./Spacer";
import BigTitle from "./BigTitle";
import Container from "./Container";
import ImageSlider from "./ImageSlider";
import NewsSlider from "./NewsSlider";
import { findCommonElements, shuffleArray } from "../helpers";
import { EXCLUDE_FROM_SEE_ARRAY } from "../constants";
import "../css/TopExperiences.css";

function SliderWithTitle({
  title,
  subtitle,
  places,
  stats /*BI*/,
  filter,
  experiences,
  link,
  isSee,
  isDeal,
  clearTheme,
  local = "en",
  isAdherent,
  isStat,
  height,
  cities,
  random, 
  euro
}) {
  const [docs, setDocs] = useState([]);
    
  useEffect(() => {
    if (isSee && !filter.tags[0]) {
      setDocs(
        places.filter((place) => {
          const slugs = place.tags.map((p) => p.slug);
          const commentEl = findCommonElements(slugs, EXCLUDE_FROM_SEE_ARRAY);
          return !commentEl;
        })
      );
    } else if (isSee && filter.tags[0]) {
      setDocs(
        places.filter((place) => {
          const slugs = place.tags.map((p) => p.slug);
          const filteredSlugs = slugs.filter((s) => {
            return EXCLUDE_FROM_SEE_ARRAY.indexOf(s) === -1;
          });

          const commentEl = findCommonElements(filteredSlugs, filter.tags);

          return commentEl;
        })
      );
    } else if (experiences) {
        const trendingIds = (stats && stats[0] && stats[0]._id) ? stats.slice(0,3).map(i=>i._id) : []
      setDocs(
          experiences.map((e) => {
          return {
            ...e.places[0],
              title: e.title,
            isTending: trendingIds.indexOf(e._id)>-1,
            isExperience: true,
            link: "/" + local + "/experience/" + e._id,
          };
        })
      );
    } else if (filter && !experiences && filter.tags && filter.tags[0]) {
      const query = filter[Object.keys(filter)[0]];
      const docsToset = places.filter((place) => {
        if (Array.isArray(query)) {
          return query.map(
            (q) => place.tags.map((p) => p.slug).indexOf(q) > -1
          )[0];
        } else {
          return place[Object.keys(filter)[0]] === query;
        }
      });
      setDocs(docsToset);
    } else {
      setDocs(places);
    }
  }, [places, filter, experiences, isSee, local, stats]);

  return (
    <Container>
      <LazyLoad height={200} offset={100} once>
        <Fade top>
          <BigTitle
            title={title}
            subtitle={subtitle}
            link={link}
            clearTheme={clearTheme}
          />
          <Spacer top="50px" />
          {isAdherent ? (
            <NewsSlider
              isStat={isStat}
              data={docs}
              height={height}
              clearTheme={clearTheme}
              local={local}
            />
          ) : (
            <ImageSlider
                data={random ? shuffleArray(docs) : docs}
                height={"370px"}
                clearTheme={clearTheme}
                local={local}
                cities={cities}
                isDeal={isDeal}
                euro={ euro }
                
            />
          )}

          <Spacer bottom="40px" />
        </Fade>
      </LazyLoad>
    </Container>
  );
}

export default SliderWithTitle;
