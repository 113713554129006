import { SET_TAGS } from '../actions/tags'

const initState = {
    tags: []
}
  
export default function tagsReducer (state = initState, action) {
    switch (action.type) {
      case SET_TAGS:
            return {
              tags: action.payload.sort((a,b)=>a.order-b.order)
        }
      default:
        return state
    }
  }
  
