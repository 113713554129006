import React from 'react'
import Lottie from 'react-lottie';
import isMobile from 'ismobilejs'
import * as animationData from '../animations/vt-loading.json'

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const size = isMobile().phone ? 300 : 700


export default function TourBuilderContainer({mobile}) {
    return (
      <div>
        <Lottie options={defaultOptions} height={mobile ? 300 : size} width={mobile ? 300 : size} />
      </div>
    );



}

