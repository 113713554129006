import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {  Row } from "antd";
import AdherentNews from './AdherentNews'
import AdherentSeo from './AdherentsSeo'
import Statistics from './Statistics'
import PublicationsAndStudies from './PublicationsAndStudiesFixVercel'
import BigTitle from "../../components/BigTitle";
import Spacer from "../../components/Spacer";
import Container from "../../components/Container";
import { getSafe, cdnImage } from "../../helpers";

function AdherentNewsDetail({ local, articles, route, cities }) {
    const routeId = getSafe(() => route.match.params.id);
    const routeCat = getSafe(() => route.match.params.cat)
    //publications_studies
    //stats
    //news
    
  const [currentArticle, setCurrentArticle] = useState({});

  useEffect(() => {
    let thisArticle =
      articles && Array.isArray(articles)
        ? articles.filter(p => p._id === routeId)[0]
        : {};
    if (thisArticle) {
      setCurrentArticle(thisArticle);
    }
  }, [routeId, articles, setCurrentArticle]);

  return <div>
      <AdherentSeo title={currentArticle.title} />
        {currentArticle.featuredImage && routeCat !== 'stats' && <img alt={currentArticle.title} src={cdnImage(currentArticle.featuredImage)} style={{width:'100%', maxHeight:'50vh', objectFit:'cover'}}/>}

        <Container>

        <Spacer top={'50px'} />
       
        <Row style={{ textAlign: 'center' }}><BigTitle title={currentArticle.title} /></Row>
        <Spacer top={'10px'} />
        <Row>
        <div
        style={{margin:'10% 10%'}}
      dangerouslySetInnerHTML={{
       __html: currentArticle.content
      }}></div>
            
        </Row>
       
      
        </Container>

        {
            routeCat === 'stats' &&  <Statistics
            cities={cities}
            isAdherent
           title={'Statistics'}
           subtitle="Key statistics about Tangier, Tetouan & Al Hoceima"
           places={articles}
          
           
             local={local}
            />
            
            
        }

        {
            routeCat === 'news' && <AdherentNews articles={articles} local={local} />
        }

        {
            routeCat === 'publications_studies' &&    <PublicationsAndStudies
            isAdherent
      
           title={'Publications et Etudes'}
           subtitle="Key statistics about Tangier, Tetouan & Al Hoceima"
           places={articles}
             local={local}
          />
        }
       
    
    </div>;
}

const mapStateToProps = state => {
  return {
    ...state.Local,
      ...state.Articles,
      ...state.Cities

  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const appConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdherentNewsDetail);

export default appConnect;
